import {CommonGameParams, GameResult, SimpleEntity} from "../../types/types"
import {getGameFilter, getRandomNumberInRange, shuffleArray} from "../../utils/helpers"
import {fetchData} from "../api"
import {dynamicParams} from "../params"

interface PlayerSetup {
    playerNumber :number
    siSpirits :SimpleEntity[]
    siAspects :(SimpleEntity | undefined)[]
    color :string | null
}

// Utility function to generate all valid groupings
function generateGroupings(total: number): number[][] {
    if (total === 0) return [[]] // Base case: no boards to allocate
    const result: number[][] = []

    for (let i = 1; i <= total; i++) {
        const remainingGroupings = generateGroupings(total - i)
        for (const group of remainingGroupings) {
            result.push([i, ...group])
        }
    }
    // Filter out invalid groupings where the sum does not equal the total
    return result.filter(group => group.reduce((sum, val) => sum + val, 0) === total)
}

// Utility function to randomly select one of the valid groupings
function getRandomIsletConfiguration(totalBoards: number): number[] {
    const allGroupings = generateGroupings(totalBoards).filter(group => group.length > 1)
    const randomIndex = Math.floor(Math.random() * allGroupings.length)
    return allGroupings[randomIndex]
}

function describeIsletConfiguration(isletConfig: number[]): string {
    const numIslets = isletConfig.length
    const isletSizes = isletConfig.map((size, index) => {
        return `Islet ${index + 1}: ${size} board${size > 1 ? 's' : ''}`
    }).join(', ')

    return `You will have ${numIslets} islet${numIslets > 1 ? 's' : ''}: ${isletSizes}.`
}

async function selectSpirits(params :CommonGameParams, gameFilter :number[] ) {
    const rawSpirits :SimpleEntity[] = await fetchData('spirit-island-spirits', dynamicParams(['Name', 'Complexity'], gameFilter, ['image'], [{Complexity: {
            $in: params.spiritComplexity,
        }}]))
    const shuffledSpirits :SimpleEntity[] = params["exclude_spirit-island-spirits"] ? shuffleArray(rawSpirits.filter(item  => !params["exclude_spirit-island-spirits"]?.includes(item.id) )) : shuffleArray(rawSpirits)
    return shuffledSpirits.slice(0, params.players)
}

async function selectSpiritAspects(params :CommonGameParams, gameFilter :number[], selectedSpirits :SimpleEntity[] ) {
    const selectedAspects :(SimpleEntity | undefined )[] = []
    const rawSpiritsAspects = await fetchData('spirit-island-aspects', dynamicParams(['Name'], gameFilter, ['image', 'spirit_island_spirit']))
    const shuffleAspects = params["exclude_spirit-island-aspects"] ? shuffleArray(rawSpiritsAspects.filter((item :SimpleEntity)  => !params["exclude_spirit-island-aspects"]?.includes(item.id) )) : shuffleArray(rawSpiritsAspects)
    selectedSpirits.forEach((spirit :SimpleEntity) => {
        const aspectForSpirit :SimpleEntity | undefined = shuffleAspects.find((aspect) => aspect.attributes.spirit_island_spirit?.data?.id === spirit.id)
        selectedAspects.push(aspectForSpirit)
    })

    return selectedAspects
}

async function getAllBoards(params :CommonGameParams, gameFilter :number[], boardType :string) {
    const rawBoards :SimpleEntity[] = await fetchData('spirit-island-boards', dynamicParams(['Name', 'Type', 'players_number'], gameFilter, ['image', 'no_compatible_with', 'conjoined_boards'], [{Type: {
            $in: boardType,
        }}]))
    const filterExcludedBoards :SimpleEntity[] = rawBoards.filter(item  => !params["exclude_spirit-island-boards"]?.includes(item.id) )
    const shuffledBoards :SimpleEntity[] = shuffleArray(filterExcludedBoards)

    return shuffledBoards
}

function selectBalancedBoards (allBoards :SimpleEntity[], isAllowBannedCombo: boolean, playerCountForBoards :number) {
    let selectedBoards: SimpleEntity[] = []

    if(playerCountForBoards <= 4 && !isAllowBannedCombo) {
        for (let i = 0; i < allBoards.length && selectedBoards.length < playerCountForBoards; i++) {
            const board = allBoards[i]
            // Check if the board is compatible with all already selected boards
            const isCompatible = selectedBoards.every(selectedBoard => {
                const selectedNoCompatibleWith = selectedBoard.attributes.no_compatible_with?.data
                return !selectedNoCompatibleWith || selectedNoCompatibleWith.id !== board.id
            })

            if (isCompatible) {
                selectedBoards.push(board)
            }
        }
    } else {
        selectedBoards = allBoards.slice(0, playerCountForBoards)
    }

    return selectedBoards
}

function selectThematicBoards (allBoards :SimpleEntity[], thematicLayout :string, playerCountForBoards :number) {
    let selectedBoards: SimpleEntity[] = []
    if(thematicLayout === 'random') {

        // get random thematic boards that have connections
        const usedBoardIds: Set<number> = new Set()

        let currentBoard = allBoards[0]
        selectedBoards.push(currentBoard)
        usedBoardIds.add(currentBoard.id)

        while (selectedBoards.length < playerCountForBoards) {
            let conjoinedBoards = currentBoard.attributes.conjoined_boards.data
                .map((board) => allBoards.find(b => b.id === board.id))
                .filter((board) => board && !usedBoardIds.has(board.id)) as SimpleEntity[]

            if (conjoinedBoards.length === 0) {
                // Fallback: Select a random board from the remaining unselected boards
                const remainingBoards = allBoards.filter(board => !usedBoardIds.has(board.id))
                if (remainingBoards.length === 0) {
                    break // No more boards available, exit the loop
                }
                currentBoard = remainingBoards[Math.floor(Math.random() * remainingBoards.length)]
            } else {
                // Randomly select the next conjoined board
                currentBoard = conjoinedBoards[Math.floor(Math.random() * conjoinedBoards.length)]
            }

            selectedBoards.push(currentBoard)
            usedBoardIds.add(currentBoard.id)
        }
    } else {
        // if definitive thematic layout, then get only one south board for 5 players
        if(playerCountForBoards === 5) {
            // Step 1: Filter out the "South East" and "South West" boards
            const filteredThematicBoards = allBoards.filter(board =>
                board.attributes.Name !== "South East" && board.attributes.Name !== "South West"
            )
            // Step 2: Get the "South East" and "South West" boards
            const southBoards = allBoards.filter(board =>
                board.attributes.Name === "South East" || board.attributes.Name === "South West"
            )
            // Step 3: Randomly select one of the "South" boards
            const randomSouthBoard = southBoards[Math.floor(Math.random() * southBoards.length)]
            // Step 4: Return the combined list of filtered boards and the randomly selected South board
            selectedBoards = [...filteredThematicBoards, randomSouthBoard]
        } else {
            selectedBoards = allBoards.filter(board =>
                board.attributes.players_number <= playerCountForBoards
            )
        }
    }

    return selectedBoards
}

async function selectLayout(params :CommonGameParams, gameFilter :number[], playerCountForBoards :number, layoutType :string) {
    let selectedLayout: SimpleEntity[] = []
    const rawLayouts :SimpleEntity[] = await fetchData('spirit-island-layouts', dynamicParams(['Name', 'players_number', 'is_alternative'], gameFilter, ['image']))
    const filterExcludedLayouts :SimpleEntity[] = rawLayouts.filter(item  => !params["exclude_spirit-island-layouts"]?.includes(item.id) )

    if(filterExcludedLayouts.length > 0) {
        const playerCountLayouts :SimpleEntity[] = filterExcludedLayouts.filter(layout => layout.attributes.players_number === playerCountForBoards)
        const shuffledLayouts :SimpleEntity[] = shuffleArray(playerCountLayouts)

        if(layoutType === 'standard') {
            selectedLayout.push(shuffledLayouts.filter(layout  => !layout.attributes.is_alternative)[0])
        } else {
            selectedLayout.push(shuffledLayouts.filter(layout  => layout.attributes.is_alternative)[0])
        }
    }

    return selectedLayout
}

async function selectAdversary(params :CommonGameParams, gameFilter :number[]) :Promise<[SimpleEntity[], number]> {
    let difficulty = 0
    let selectedAdversary :SimpleEntity[] = []
    let isAdv = params.useAdversary !== 'no'
    let isSecondAdv = params.secondAdversary !== 'no'
    if(params.useAdversary === 'maybe') {
        isAdv = getRandomNumberInRange(0, 1) === 1
    }
    if(params.secondAdversary === 'maybe') {
        isSecondAdv = getRandomNumberInRange(0, 1) === 1
    }
    if(isAdv) {
        const rawAdversary :SimpleEntity[] = await fetchData('spirit-island-adversaries', dynamicParams(['Name'], gameFilter, ['image', 'siLevels']))
        const shuffleAdversary :SimpleEntity[] = params["exclude_spirit-island-adversaries"] ? shuffleArray(rawAdversary.filter((item :SimpleEntity)  => !params["exclude_spirit-island-adversaries"]?.includes(item.id) )) : shuffleArray(rawAdversary)
        if(shuffleAdversary.length > 0) {
            selectedAdversary = shuffleAdversary.length > 0 ? [shuffleAdversary[0]] : []
            const adversaryLevel = shuffleArray(selectedAdversary[0].attributes.Levels)

            if(isSecondAdv && shuffleAdversary.length > 1) {
                if (shuffleAdversary.length > 0) {
                    selectedAdversary.push(shuffleAdversary[1])
                }
                const secondAdversaryLevel = shuffleArray(selectedAdversary[1].attributes.Levels)

                // Adjust lower Difficulty to 60%
                if (adversaryLevel[0].Difficulty < secondAdversaryLevel[0].Difficulty) {
                    adversaryLevel[0].Difficulty = Math.round(adversaryLevel[0].Difficulty * 0.6)
                } else {
                    secondAdversaryLevel[0].Difficulty = Math.round(secondAdversaryLevel[0].Difficulty * 0.6)
                }

                selectedAdversary[1].attributes.Name = `${selectedAdversary[1].attributes.Name} / Level: ${secondAdversaryLevel[0].Level} (+${secondAdversaryLevel[0].Difficulty})`
                difficulty += secondAdversaryLevel[0].Difficulty
            }
            selectedAdversary[0].attributes.Name = `${selectedAdversary[0].attributes.Name} / Level: ${adversaryLevel[0].Level} (+${adversaryLevel[0].Difficulty})`
            difficulty += adversaryLevel[0].Difficulty
        }
    }
    return [selectedAdversary, difficulty]
}

async function selectScenario(params :CommonGameParams, gameFilter :number[]) {

    let selectedScenario :SimpleEntity[] = []
    let isScenario = params.useScenario !== 'no'
    if(params.useScenario === 'maybe') {
        isScenario = getRandomNumberInRange(0, 1) === 1
    }
    if(isScenario) {
        const rawScenarios :SimpleEntity[] = await fetchData('spirit-island-scenarios', dynamicParams(['Name', 'difficulty'], gameFilter, ['image']))
        const shuffleScenarios :SimpleEntity[] = params["exclude_spirit-island-scenarios"] ? shuffleArray(rawScenarios.filter((item :SimpleEntity)  => !params["exclude_spirit-island-scenarios"]?.includes(item.id) )) : shuffleArray(rawScenarios)
        selectedScenario = shuffleScenarios.length < 0 ? [shuffleScenarios[0]] : []
    }

    return selectedScenario
}
export const spiritIsland = async (params :CommonGameParams): Promise<GameResult> => {
    let combinedDifficulty = 0
    let isInRange = false
    let maxAttempts = 50
    let attempt = 0
    let mapSettings = ''
    let selectedSpirits: SimpleEntity[] = []
    let selectedAspects: (SimpleEntity | undefined)[] = []
    let selectedBoards: SimpleEntity[] = []
    let selectedLayout: SimpleEntity[] = []
    let archipelagoDescription: SimpleEntity[] | string = []
    let selectedAdversary: SimpleEntity[] = []
    let selectedScenario: SimpleEntity[] = []
    let shuffledColors: string[] | null = null

    // Id's of game and expansions
    const gameFilter :number[] = getGameFilter(parseInt(params.gameId), params.expansions)

    // Determinate allowed max players based on selected expansions
    const maxPlayers :6|4 = params.expansions.includes(100) ? 6 : 4

    // Determinate if larger island should be used
    let isLargerIsland = params.largerIsland !== 'no'
    if(params.largerIsland === 'maybe') {
        isLargerIsland = getRandomNumberInRange(0, 1) === 1
    }
    const playerCountForBoards :number = isLargerIsland ? Math.min(params.players + 1, maxPlayers) : params.players

    // Determinate if banned boards combo should be used
    let isAllowBannedCombo = params.allowBannedCombo !== 'no'
    if(params.allowBannedCombo === 'maybe') {
        isAllowBannedCombo = getRandomNumberInRange(0, 1) === 1
    }
    while (!isInRange && attempt < maxAttempts) {
        attempt++
        combinedDifficulty = 0
        mapSettings = ''
        selectedSpirits = []
        selectedAspects = []
        selectedBoards = []
        selectedLayout = []
        archipelagoDescription = []
        selectedAdversary = []
        selectedScenario = []

        // Spirits Selection
        selectedSpirits = await selectSpirits(params, gameFilter)

        // Spirits Aspects selection
        const isAspects = params.useAspects === 'yes' || (params.useAspects === 'maybe' && getRandomNumberInRange(0, 1) === 1)
        if (isAspects) {
            selectedAspects = await selectSpiritAspects(params, gameFilter, selectedSpirits)
        }

        // Select Boards and Layouts
        const boardLayout: string = shuffleArray(params.boardLayouts)[0]
        let allBoards: SimpleEntity[] = []

        // First Select boards by Type and player cont(including Larger Island and Allow banned board combos options)
        switch (boardLayout) {
            case 'standard':
            case 'alternate':
            case 'archipelago':
                allBoards = await getAllBoards(params, gameFilter, 'balanced')
                selectedBoards = allBoards.length > 0 ? selectBalancedBoards(allBoards, isAllowBannedCombo, playerCountForBoards) : []
                break
            case 'thematicTokens':
            case 'thematicNoTokens':
                allBoards = await getAllBoards(params, gameFilter, 'thematic')
                selectedBoards = allBoards.length > 0 ? selectThematicBoards(allBoards, shuffleArray(params.thematicLayout)[0], playerCountForBoards) : []
                break
            default:
                break
        }

        // Then select Layouts
        switch (boardLayout) {
            case 'standard':
                selectedLayout = await selectLayout(params, gameFilter, playerCountForBoards, 'standard')
                mapSettings = 'Standard Layout (+0)'
                break
            case 'alternate':
                selectedLayout = await selectLayout(params, gameFilter, playerCountForBoards, 'alternate')
                mapSettings = 'Alternate Layout (+0)'
                break
            case 'archipelago':
                const isletConfig: number[] = getRandomIsletConfiguration(playerCountForBoards)
                archipelagoDescription = describeIsletConfiguration(isletConfig)
                combinedDifficulty += 1
                mapSettings = 'Archipelago Layout (+1)'
                break
            case 'thematicTokens':
                combinedDifficulty += 1
                mapSettings = 'Thematic (+tokens) (+1)'
                break
            case 'thematicNoTokens':
                combinedDifficulty += 3
                mapSettings = 'Thematic (no tokens) (+3)'
                break
            default:
                break
        }
        // End Of Select Boards and Layouts

        // Select Adversaries
        const adversaries: [SimpleEntity[], number] = await selectAdversary(params, gameFilter)
        selectedAdversary = adversaries[0]
        if (selectedAdversary.length > 0) {
            combinedDifficulty += adversaries[1]
        }

        // Select Scenarios
        selectedScenario = await selectScenario(params, gameFilter)
        if (selectedScenario.length > 0) {
            combinedDifficulty += selectedScenario[0].attributes.difficulty
        }

        if (isLargerIsland && playerCountForBoards !== params.players) {
            switch (true) {
                case (combinedDifficulty >= 0 && combinedDifficulty < 3):
                    combinedDifficulty += 2
                    mapSettings += ' / plus one board (+2)'
                    break
                case (combinedDifficulty >= 3 && combinedDifficulty < 6):
                    combinedDifficulty += 3
                    mapSettings += ' / plus one board (+3)'
                    break
                case (combinedDifficulty >= 6):
                    combinedDifficulty += 4
                    mapSettings += ' / plus one board (+4)'
                    break
                default:
                    break
            }
        }

        isInRange = combinedDifficulty >= Math.min(...params.siDifficulty) && combinedDifficulty <= Math.max(...params.siDifficulty)
    }

    if (!isInRange) {
        return {
            gameId: params.gameId,
            playersSetup: [],
            commonSetup: {
                siNoResults: `No Setup found for this difficulty range after ${maxAttempts} attempts`,
            }
        }
    }

    // Proceed with a valid result
    shuffledColors = params.colors ? shuffleArray(params.colors) : null

    // Initialize arrays to store final selections
    const playersSetup: PlayerSetup[] =
        Array.from({ length: params.players }, (setup, index) =>
            ({
                playerNumber: index + 1,
                siSpirits: selectedSpirits[index] ? [selectedSpirits[index]] : [],
                siAspects: selectedAspects[index] ? [selectedAspects[index]] : [],
                color: shuffledColors ? shuffledColors[index] : null,
            }))

    return {
        gameId: params.gameId,
        playersSetup: playersSetup,
        commonSetup: {
            siBoards: selectedBoards,
            siMap: mapSettings,
            siLayout: selectedLayout.length > 0 && selectedLayout[0] ? selectedLayout : null,
            siLayoutArchipelago: archipelagoDescription ? archipelagoDescription : null,
            siAdversary: selectedAdversary,
            siScenario: selectedScenario,
            siDifficulty: combinedDifficulty,
        }
    }
}
