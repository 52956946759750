import {Alert, Button, Collapse, Divider, Flex, Grid, Space, Switch, Tooltip, Typography} from "antd";
import {InfoCircleOutlined, RollbackOutlined} from "@ant-design/icons";
import {BggIcon} from "../../utils/customIcons";
import {isSmallScreen} from "../../utils/myBreakpoints";
import React from "react";
import {Game, GameResult} from "../../types/types";
import {NavigateFunction} from "react-router-dom";
import {BlocksRenderer} from "@strapi/blocks-react-renderer";

interface GameDetailsProps {
    game : Game | null;
    navigate :NavigateFunction;
    config :any;
    setNoImageGenerate: (checked: boolean) => void;
    gameResults :GameResult | undefined;
    clearGameResults: () => void;
}

type BlockNode = {
    type: string;
    children?: Array<BlockNode>;
    text?: string;
};

const isTextNode = (node: BlockNode): node is { type: "text"; text: string } => {
    return node.type === "text" && typeof node.text === "string";
};

const { useBreakpoint } = Grid;
const GameDetails: React.FC<GameDetailsProps> = ({ game, navigate, config, setNoImageGenerate, gameResults, clearGameResults}) => {
    const breakpoints = useBreakpoint();
    const isGameInfoEmpty = game?.attributes.game_info ? game?.attributes.game_info.every(block => {
        if (!block.children || block.children.length === 0) return true;
        // @ts-ignore
        return block.children.every(child => isTextNode(child) && child.text.trim() === "");
    }) : true;
    return (
        <>
            <br/>
            {game?.attributes.game_msg && (
                <Alert
                    message={game?.attributes.game_msg}
                    type="warning" showIcon
                />
            )}
            <Space align="center" wrap>
                <Button icon={<RollbackOutlined />} onClick={() => navigate('/')} key="back-btn" />
                <h1 key="page-title">{game?.attributes.game_name}</h1>
                {config && config.hasImages &&
                  <>
                    <Space>
                      (Generate without images <Switch checkedChildren="Yes" unCheckedChildren="No"
                                                       onChange={(checked: boolean) => {
                                                           setNoImageGenerate(checked);
                                                       }} />
                      <Tooltip title="If Game Has images in DataBase this option allows to show results without loading them">
                        <InfoCircleOutlined />
                      </Tooltip>)
                    </Space>
                  </>
                }
                <Typography.Link href={game?.attributes.link_on_bgg} target="_blank"><BggIcon  /></Typography.Link>
            </Space>
            {isSmallScreen(breakpoints) && <Divider style={{marginTop: 12}}></Divider>}
            {!isGameInfoEmpty && (
                <>
                    <Collapse items={[
                        {
                            key: '1',
                            label: 'Instructions info',
                            children: <BlocksRenderer content={game?.attributes.game_info || []} />
                        }
                    ]} size="large" />
                    <br/>
                </>
            )}
            {game && gameResults && (
                <>
                    <Flex vertical style={{maxWidth: 500}}>
                        <Button type="primary" danger onClick={clearGameResults}>Clear Game Results from Local storage</Button>
                        <p>The last generated game result data is stored in your browser's local storage, so you can leave, refresh, or close the page without losing it.</p>
                    </Flex>
                    <br/>
                </>
            )}
        </>
    )
}
export default GameDetails;
