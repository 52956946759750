import {CommonGameParams, GameResult, SimpleEntity} from "../../types/types";
import {filterUniquePairs, getGameFilter, shuffleArray} from "../../utils/helpers";
import {fetchData} from "../api";
import {dynamicParams} from "../params";

interface PlayerSetup {
    playerNumber :number;
    color :string | null;
    leader :SimpleEntity[];
}
export const lrArnak = async (params :CommonGameParams): Promise<GameResult> => {

    // Id's of game and expansions
    const gameFilter :number[] = getGameFilter(parseInt(params.gameId), params.expansions)
    let selectedLeaders :SimpleEntity[] = []

    if(gameFilter.includes(116) || gameFilter.includes(117)) {
        const rawLeaders :SimpleEntity[] = await fetchData('arnak-leaders', dynamicParams(['Name', 'is_dummy'], gameFilter, ['image']));
        const shuffledLeaders :SimpleEntity[] = params["exclude_arnak-leaders"] ? shuffleArray(rawLeaders.filter(item  => !params["exclude_arnak-leaders"]?.includes(item.id) )) : shuffleArray(rawLeaders);
        if(gameFilter.includes(116) || params.players < 3) {
            selectedLeaders = shuffledLeaders.filter((leader :SimpleEntity) => !leader.attributes.is_dummy);
        } else if(gameFilter.includes(117) && !gameFilter.includes(116) && params.players === 3) {
            const dummyLeaders = shuffledLeaders.filter((leader :SimpleEntity) => leader.attributes.is_dummy);
            selectedLeaders = shuffledLeaders.filter((leader :SimpleEntity) => !leader.attributes.is_dummy);
            selectedLeaders.push(dummyLeaders[0])
            selectedLeaders = shuffleArray(selectedLeaders)
        } else {
            selectedLeaders = shuffledLeaders
        }
    }

    let selectedTemple :SimpleEntity[] = []

    if(gameFilter.includes(116) || gameFilter.includes(117)) {
        const rawTemples :SimpleEntity[] = await fetchData('arnak-temples', dynamicParams(['Name', 'PairId'], gameFilter, ['image']));
        const shuffledTemples :SimpleEntity[] = params["exclude_arnak-temples"] ? shuffleArray(rawTemples.filter(item  => !params["exclude_arnak-temples"]?.includes(item.id) )) : shuffleArray(rawTemples);
        const filterPairsTemple :SimpleEntity[] = filterUniquePairs(shuffledTemples)
        selectedTemple = filterPairsTemple.length > 0 ? [filterPairsTemple[0]] : []

        if(selectedTemple.length > 0 && (selectedTemple[0].attributes.Name === "Bird Temple" || selectedTemple[0].attributes.Name === "Snake Temple")) {
            selectedTemple= []
        }
    }

    const rawBoards :SimpleEntity[] = await fetchData('arnak-board-sides', dynamicParams(['Name'], gameFilter, ['image']));
    let selectedBoard :SimpleEntity[] = [shuffleArray(rawBoards)[0]]

    const shuffledColors :string[] | null = params.colors ? shuffleArray(params.colors) : null
    // Initialize arrays to store final selections
    const playersSetup :PlayerSetup[] =
        Array.from({ length: params.players }, (setup, index) =>
            ({
                playerNumber: index + 1,
                leader: selectedLeaders[index] ? [selectedLeaders[index]] : [],
                color: shuffledColors ? shuffledColors[index] : null,
            }));

    return {
        gameId: params.gameId,
        playersSetup: playersSetup,
        commonSetup: {
            temple: selectedTemple,
            board: selectedBoard,
        }
    };

}
