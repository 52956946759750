import {Button, Col, Form, Row, CheckboxProps, Space, Divider, List, Typography, Image} from 'antd'
import React, {useEffect, useState} from 'react'
import {useParams, useNavigate, NavigateFunction} from 'react-router-dom'
import {fetchData, fetchGameByName} from "../services/api"
import {Game, GameColor, GameEntities, GameResult, SimpleEntity} from "../types/types"
import {getRandomStaff} from "../services/games"
import UnmatchedPlayerResult from "../components/UnmatchedPlayerResult"
import UnmatchedCommonResult from "../components/UnmatchedCommonResult"
import {onDrawCivilization} from "../services/games/tapestry"
import GameResultComponent from "../components/GameResult"
import {FileImageOutlined,} from "@ant-design/icons"
import {dynamicParams} from "../services/params"
import GameDetails from "../components/GamePage/GameDetails"
import PlayerSetup from "../components/GamePage/PlayerSetup"
import Expansions from "../components/GamePage/Expansions"
import SpecialOptions from "../components/GamePage/SpecialOptions"
import ExcludedItems from "../components/GamePage/ExcludedItems"
import ColorsSetup from "../components/GamePage/ColorsSetup"
import DiceLoader from "../components/DiceLoader"
import {parentConfig} from "../config"
import SpiritIslandOptions from "../components/GamePage/SpiritIslandOptions"
import TeotihuacanOptions from "../components/GamePage/TeotihuacanOptions";
import {onDrawNeutralDices, onDrawTechnology} from "../services/games/teotihuacan";
import {noImageFound} from "../utils/helpers";
import CalicoResults from "../components/CalicoResults";
import ArnakResults from "../components/ArnakResults";
import OrleansResults from "../components/OrleansResults";

interface ExpPlayerCount {
    id: number
    min: number | null
    max: number | null
}

const getGameSetupValues = (gameSlug :string) => localStorage.getItem(`${gameSlug}-setup`)
    ? JSON.parse(localStorage.getItem(`${gameSlug}-setup`) as string)
    : null

const GamePage = () => {
    let navigate :NavigateFunction = useNavigate()

    type CheckboxValueType = number

    const { gameSlug } = useParams<{ gameSlug: string }>()
    const [game, setGame] = useState<Game | null>(null)
    const [gameId, setGameId] = useState<string>('')
    const [loading, setLoading] = useState(true)
    const [loadingMsg, setLoadingMsg] = useState<string>('Loading Game Details')
    const [error, setError] = useState<string | null>(null)
    const [selectedExpansions, setSelectedExpansions] = useState<CheckboxValueType[]>([])
    const [allExpansionsIds, setAllExpansionsIds] = useState<number[]>([])
    const [expPlayersCount, setExpPlayersCount] = useState<
        ExpPlayerCount[] | undefined
    >([])
    const [numberOfPlayers, setNumberOfPlayers] = useState<number | undefined>()
    const [playerRange, setPlayerRange] = useState<number[]>([])
    const [gameResults, setGameResults] = useState<GameResult>()
    const [noImageGenerate, setNoImageGenerate] = useState<boolean>(false)
    const [config, setConfig] = useState<any>()
    const [form] = Form.useForm()
    const [randomizedEntities, setRandomizedEntities] = useState<{ [key: string]: GameEntities }>({})
    const [activeCollapseKeys, setActiveCollapseKeys] = useState<string[]>([])
    const [selectedEntities, setSelectedEntities] = useState<{ [key: string]: number[] }>({})
    const [indeterminateStates, setIndeterminateStates] = useState<{ [key: string]: boolean }>({})
    const [checkAllStates, setCheckAllStates] = useState<{ [key: string]: boolean }>({})
    const [mergedColors, setMergedColors] = useState<GameColor[]>([])
    const [drawDisabled , setDrawnDisabled] = useState<boolean>(false)
    // Tapestry related
    const [civilizationsInUse , setCivilizationsInUse] = useState<string[]>([])
    const [drawnCivilizations , setDrawnCivilizations] = useState<SimpleEntity[]>([])
    // Teotihuacan Related
    const [neutralDicesSetupInUse , setNeutralDicesSetupInUse] = useState<string[]>([])
    const [drawnNeutralDicesSetup , setDrawnNeutralDicesSetup] = useState<SimpleEntity[]>([])
    const [drawTeoTechnology , setDrawTeoTechnology] = useState<boolean>(false)
    const [drawnTeoTechnology , setDrawnTeoTechnology] = useState<SimpleEntity[]>([])

    useEffect(() => {
        // check if expansion allow more players than base game
        const getExpPlayersCount = (data: { attributes: { expansions: { data: any[] } } }) => {
            const expData = data?.attributes.expansions.data.map(exp => ({
                id: exp.id,
                min: exp.attributes.exp_min_players,
                max: exp.attributes.exp_max_players,
            }))
            setExpPlayersCount(expData || [])
        }
        const loadGame = async () => {
            if(gameSlug) {
                try {
                    const data = await fetchGameByName(gameSlug)
                    if (process.env.REACT_APP_D) {
                        console.log("game data: ", data)
                    }
                    setGameId(`${data.id}`)
                    setGame(data)

                    // Get Results from Storage
                    const resultFromStorage = localStorage.getItem(data.id)
                        ? JSON.parse(localStorage.getItem(data.id) as string)
                        : null;

                    if(resultFromStorage) {
                        setGameResults(resultFromStorage)
                        if(gameSlug === 'teotihuacan-city-of-gods') {
                            teoAdditionalDraws(resultFromStorage)
                        }
                    }

                    // Get Tapestry Civilizations from storage
                    const drawnCivsFromLS = localStorage.getItem('tapestry-drawn-civs')
                        ? JSON.parse(localStorage.getItem('tapestry-drawn-civs') as string)
                        : []
                    setDrawnCivilizations(drawnCivsFromLS);

                    const drawnCivsFromLSInUse = localStorage.getItem('tapestry-civs-in-use')
                        ? JSON.parse(localStorage.getItem('tapestry-civs-in-use') as string)
                        : []
                    setCivilizationsInUse(drawnCivsFromLSInUse);

                    // Get Teotihuacan Neutral Dices Setup Cards from LS
                    const drawnND = localStorage.getItem('teo-drawn-neutral-dices')
                        ? JSON.parse(localStorage.getItem('teo-drawn-neutral-dices') as string)
                        : []
                    setDrawnNeutralDicesSetup(drawnND);

                    const drawnNDInUse = localStorage.getItem('teo-drawn-neutral-in-use')
                        ? JSON.parse(localStorage.getItem('teo-drawn-neutral-in-use') as string)
                        : []
                    setNeutralDicesSetupInUse(drawnNDInUse);


                    setLoading(false)
                    setLoadingMsg('')
                    document.title = `${data.attributes.game_name} - Tabletop Setup Wizard` // Update page title

                    const ids = data.attributes.expansions.data.map((exp: any) => exp.id)
                    setAllExpansionsIds(ids)
                    getExpPlayersCount(data)

                    const dbConfig = JSON.parse(data.attributes.config)

                    const gameConfig = {
                        ...parentConfig,
                        ...dbConfig
                    }
                    setConfig(gameConfig)

                    if(gameConfig.selectedExpansions) {
                        setSelectedExpansions(ids)
                    }

                    // Initialize excluded entities
                    const initialExcludedEntities: any = {}
                    if(gameConfig.gameEntities) {
                        gameConfig.gameEntities.forEach((entity: any) => {
                            initialExcludedEntities[`exclude_${entity.apiData}`] = []
                        })
                    }

                    const gameSetupValues = getGameSetupValues(gameSlug)
                    if(gameSetupValues) {
                        form.setFieldsValue(gameSetupValues)
                    }

                } catch (err) {
                    setError('Failed to load game')
                    setLoading(false)
                    setLoadingMsg('')
                }
            }
        }

        loadGame().then(() => {
        }).catch((err) => {
            console.error("Failed to load game:", err)
        })
    }, [gameSlug])

    const loadGameEntities = async (gameId: string, expansionIds: number[]) => {
        // if(config.hasOwnProperty('gameEntities')) {
            const gameFilter = [parseInt(gameId), ...expansionIds]
            const entitiesData: { [key: string]: GameEntities } = {}
            const randomizedEntitiesConf = config.gameEntities

        if(randomizedEntitiesConf) {
            for (const itemType of randomizedEntitiesConf) {
                const items = await fetchData(itemType.apiData, dynamicParams(['Name'], gameFilter, ['image', 'game']))

                const structureByExp :any[] = []

                items.forEach(item => {
                    const gameName = item.attributes.game.data.attributes.game_name
                    const existingGame = structureByExp.find(game => game.game_name === gameName)

                    if (existingGame) {
                        existingGame.items.push(item)
                    } else {
                        structureByExp.push({
                            game_id: item.attributes.game.data.id,
                            game_name: gameName,
                            items: [item]
                        })
                    }
                })

                structureByExp.sort((a, b) => a.game_id - b.game_id)
                structureByExp.forEach(item => {
                    item.items.sort((a : SimpleEntity, b :SimpleEntity) => {
                        if (a.attributes.Name < b.attributes.Name) return -1;
                        if (a.attributes.Name > b.attributes.Name) return 1;
                        return 0;
                    })
                })

                entitiesData[itemType.apiData] = {
                    title: itemType.title,
                    items: structureByExp,
                }
            }

            setRandomizedEntities(entitiesData)
        }
    }

    const loadColors = (expansionIds: number[], game: Game) => {
        const baseColors = game?.attributes.colors.data || []
        const expansionColors: GameColor[] = []

        expansionIds.forEach(expId => {
            const expansion = game.attributes.expansions.data.find(exp => exp.id === expId)
            if (expansion) {
                expansionColors.push(...expansion.attributes.colors.data)
            }
        })

        setMergedColors([...baseColors, ...expansionColors])
    }

    useEffect(() => {
        if (gameId && game) {
            loadGameEntities(gameId, selectedExpansions).then(() => {
                // Handle successful resolution if needed
            }).catch((err) => {
                console.error("Failed to load loadGameEntities:", err)
            })

            loadColors(selectedExpansions, game)
        }
    }, [game, gameId, selectedExpansions])

    useEffect(() => {
        if (game) {

            // Calculate the min and max players based on selected expansions
            let minPlayers = game?.attributes.default_min_players || 0
            let maxPlayers = game?.attributes.default_max_players || 0

            selectedExpansions.forEach(expId => {
                const expData = expPlayersCount?.find(exp => exp.id === expId)
                if (expData) {
                    if (expData.min !== null) {
                        minPlayers = Math.max(minPlayers, expData.min)
                    }
                    if (expData.max !== null) {
                        maxPlayers = Math.max(maxPlayers, expData.max)
                    }
                }
            })

            // Update player range
            const range = []
            for (let i = minPlayers; i <= maxPlayers; i++) {
                range.push(i)
            }
            setPlayerRange(range)
            const gameSetupValues = getGameSetupValues(game.attributes.gameSlug)
            numberOfPlayers ? setNumberOfPlayers(numberOfPlayers) : gameSetupValues ? setNumberOfPlayers(gameSetupValues.players) : setNumberOfPlayers(config.defaultMinPlayers ? config.defaultMinPlayers : minPlayers)
        }
    }, [game, selectedExpansions, expPlayersCount, form, numberOfPlayers, config])

    useEffect(() => {
        if (game) {
            form.setFieldsValue({ players: numberOfPlayers })
        }
    }, [numberOfPlayers, form, game])

    // Clear gameResults from LocalStorage
    const clearGameResults = (() => {
        if(gameId) {
            localStorage.removeItem(gameId)
            setGameResults(undefined)

        }
        if(gameSlug === "tapestry") {
            localStorage.removeItem('tapestry-drawn-civs')
            localStorage.removeItem('tapestry-civs-in-use')
            setCivilizationsInUse([])
            setDrawnCivilizations([])
        }
        if(gameSlug === "teotihuacan-city-of-gods") {
            localStorage.removeItem('teo-drawn-neutral-in-use')
            localStorage.removeItem('teo-drawn-neutral-dices')
            setNeutralDicesSetupInUse([])
            setDrawnNeutralDicesSetup([])
        }
    })

    const checkAll = allExpansionsIds.length === selectedExpansions.length
    const onCheckAllExp: CheckboxProps['onChange'] = (e) => {
        const checked = e.target.checked
        const newSelectedExpansions = checked ? allExpansionsIds : []
        setSelectedExpansions(newSelectedExpansions)
        form.setFieldsValue({ expansions: newSelectedExpansions })
    }
    const handleExpansionsCheck = (checkedValues: CheckboxValueType[]) => {
        setSelectedExpansions(checkedValues)
    }

    const handleSelectAllChange = (itemType: any, checked: boolean) => {
        const newSelectedEntities = { ...selectedEntities }
        newSelectedEntities[`exclude_${itemType.apiData}`] = checked ? randomizedEntities[itemType.apiData]?.items.flatMap(item => item.items.map(enItem => enItem.id)) : []
        setSelectedEntities(newSelectedEntities)

        form.setFieldsValue({...newSelectedEntities})

        const newCheckAllStates = { ...checkAllStates }
        newCheckAllStates[`exclude_${itemType.apiData}`] = checked
        setCheckAllStates(newCheckAllStates)

        const newIndeterminateStates = { ...indeterminateStates }
        newIndeterminateStates[`exclude_${itemType.apiData}`] = false
        setIndeterminateStates(newIndeterminateStates)
    }

    const handleItemChange = (itemType: any, checkedValues: number[]) => {
        const newSelectedEntities = { ...selectedEntities }
        newSelectedEntities[`exclude_${itemType.apiData}`] = checkedValues
        setSelectedEntities(newSelectedEntities)

        const newCheckAllStates = { ...checkAllStates }
        newCheckAllStates[`exclude_${itemType.apiData}`] = checkedValues.length === randomizedEntities[itemType.apiData]?.items.flatMap(item => item.items).length
        setCheckAllStates(newCheckAllStates)

        const newIndeterminateStates = { ...indeterminateStates }
        newIndeterminateStates[`exclude_${itemType.apiData}`] = checkedValues.length > 0 && checkedValues.length < randomizedEntities[itemType.apiData]?.items.flatMap(item => item.items).length
        setIndeterminateStates(newIndeterminateStates)
    }

    // Tapestry related
    const handleDrawCivilization = async () => {
        const newCiv :SimpleEntity = await onDrawCivilization({gameId: gameId, expansions: selectedExpansions, usedCivs: civilizationsInUse})
        if (newCiv) {
            const updatedDrawnCivs = [...drawnCivilizations, newCiv];
            const updatedCivilizationsInUse = [...civilizationsInUse, newCiv.attributes.Name];
            setCivilizationsInUse(updatedCivilizationsInUse)
            setDrawnCivilizations(updatedDrawnCivs)
            localStorage.setItem('tapestry-drawn-civs', JSON.stringify(updatedDrawnCivs))
            localStorage.setItem('tapestry-civs-in-use', JSON.stringify(updatedCivilizationsInUse))
        } else {
            setDrawnDisabled(true)
        }
    }
    const handleSubmit = async (values: any) => {
        localStorage.setItem(`${gameSlug}-setup`, JSON.stringify(values));
        if (process.env.REACT_APP_D) {
            console.log("handleSubmit: ", values)
        }
        setLoading(true)
        setLoadingMsg('Randomizing')
        clearGameResults()
        setDrawnTeoTechnology([])
        try {
            const results :GameResult = await getRandomStaff(values)
            setGameResults(results)
            localStorage.setItem(results.gameId, JSON.stringify(results));
            if(process.env.REACT_APP_D) {
                console.log("Received game results:", results)
            }

            // Tapestry related
            if(gameId === "1") {
                const civInUseTemp :string[] = []
                results.playersSetup.forEach((player :any) => {
                    player.civilizations.forEach((civ :SimpleEntity) => {
                        civInUseTemp.push(civ.attributes.Name)
                    })
                })
                setCivilizationsInUse(civInUseTemp)

                if(drawnCivilizations && drawnCivilizations.length > 0) setDrawnCivilizations([])
            }
            // END: Tapestry related

            // Teotihuacan related
            if(gameSlug === 'teotihuacan-city-of-gods') {
                teoAdditionalDraws(results)
            }
        } catch (error) {
            setError('Failed to process game')
            console.error("Error during game processing:", error)
        }
        setLoading(false)
        setLoadingMsg('')
        window.scrollTo({
            top: 0,
        })
    }
    const handleDrawNeutralDiceCards = async () => {
        const newCard :SimpleEntity = await onDrawNeutralDices({gameId: gameId, expansions: selectedExpansions, usedDiceCards: neutralDicesSetupInUse, players: numberOfPlayers})
        if (newCard) {
            const updatedNeutralDicesSetup = [...drawnNeutralDicesSetup, newCard];
            const updatedNeutralDicesSetupInUse = [...neutralDicesSetupInUse, newCard.attributes.Name];
            setNeutralDicesSetupInUse(updatedNeutralDicesSetupInUse)
            setDrawnNeutralDicesSetup(updatedNeutralDicesSetup);
            localStorage.setItem('teo-drawn-neutral-dices', JSON.stringify(updatedNeutralDicesSetup))
            localStorage.setItem('teo-drawn-neutral-in-use', JSON.stringify(updatedNeutralDicesSetupInUse))
        }
        if(drawnNeutralDicesSetup.length > 1) {
            setDrawnDisabled(true)
        }
    }
    const teoAdditionalDraws = (results :GameResult) => {
        results.playersSetup.forEach((player :any) => {
            if(player.teoPriests.length > 0){
                player.teoPriests.forEach((el :SimpleEntity) => {
                    if(el.attributes.Name === '11. Cōātlīcue (M6)') {
                        setDrawTeoTechnology(true)
                    }
                })
            }
        })
    }
    const handleDrawTechnologyTiles = async () => {
        const newTechnology :SimpleEntity[] = await onDrawTechnology({gameId: gameId, expansions: selectedExpansions, usedTechnologies: gameResults?.commonSetup.teoTechnology})
        setDrawnTeoTechnology(newTechnology)
    }

    if (loading) return <DiceLoader message={loadingMsg} />
    if (error) return <div>Error: {error}</div>

    const SubmitBtn = <Form.Item>
        <Button type="primary" htmlType="submit" style={{ width: '200px'}} className="ga-submit" data-name={game?.attributes.game_name} id="gaSubmit">
            Setup
        </Button>
    </Form.Item>

    const handleCollapseChange = (keys: string | string[]) => {
        setActiveCollapseKeys(keys as string[])
    }
    const API_URL = process.env.REACT_APP_MEDIA_URL;
    const exceptionGames = ['unmatched', 'dice-throne', 'calico', 'lost-ruins-of-arnak', 'orleans']
    return (
        <>
            <GameDetails
                game={game}
                config={config}
                setNoImageGenerate={setNoImageGenerate}
                navigate={navigate}
                gameResults={gameResults}
                clearGameResults={clearGameResults}
            />

            {/*Tapestry related*/}
            {(gameResults && gameId === '1') &&
              <>
                <div>
                    <Button type="primary" onClick={handleDrawCivilization} disabled={drawDisabled}>Draw additional
                      Civilization</Button>
                    <br />
                      {drawnCivilizations && drawnCivilizations.length > 0 && (
                          <>
                              <List
                                  dataSource={drawnCivilizations}
                                  renderItem={(el) => (
                                      <List.Item key={`drawnCivilizations-${el.attributes.Name}`}>
                                          {el.attributes.Name} - Complexity: {el.attributes.Complexity}
                                      </List.Item>
                                  )}
                              />
                              {drawDisabled &&
                                <h3 style={{color: 'red'}}>No more Civilization available</h3>
                              }
                          </>
                      )}
                </div>
                <br />
              </>
            }
            {/* END Tapestry related*/}
            {/* Teotihuacan */}
            {(gameResults && gameSlug === 'teotihuacan-city-of-gods' && (numberOfPlayers === 2 || numberOfPlayers === 3) ) &&
              <>
                <div>
                  <Button type="primary" onClick={handleDrawNeutralDiceCards} disabled={drawDisabled}>Draw Neutral Dice setup card</Button>
                  <br />
                    {drawnNeutralDicesSetup && drawnNeutralDicesSetup.length > 0 &&
                      <div>
                        <br />
                        <Row>
                            {drawnNeutralDicesSetup.map((card: SimpleEntity) => {
                                return <Col key={`drawnNeutralDicesSetup-${card.attributes.Name}`}>
                                    <Image
                                        width="100%"
                                        src={card.attributes.image.data ? `${API_URL}${card.attributes.image.data?.attributes.url}` : noImageFound}
                                        preview={false}
                                        fallback={noImageFound}
                                    />
                                    <p>{card.attributes.Name}</p>
                                </Col>
                            })
                            }
                        </Row>
                      </div>
                    }
                </div>
                <br />
              </>
            }

            {gameResults && gameSlug === 'teotihuacan-city-of-gods' && drawTeoTechnology &&
              <>
                <div>
                <Button type="primary" onClick={handleDrawTechnologyTiles}>Draw
                  Technology Tiles</Button> For Priest 11. Cōātlīcue (M6)
                <br />
                  {drawnTeoTechnology.length > 0 &&
                    <div>
                      <br />
                      <Row>
                          {drawnTeoTechnology.map((card: SimpleEntity) => {
                              return <Col key={`drawnTeoTechnology-${card.attributes.Name}`}>
                                  <Image
                                      width="100%"
                                      src={card.attributes.image.data ? `${API_URL}${card.attributes.image.data?.attributes.url}` : noImageFound}
                                      preview={false}
                                      fallback={noImageFound}
                                  />
                                  <p>{card.attributes.Name}</p>
                              </Col>
                          })
                          }
                      </Row>
                    </div>
                  }
                </div>
                <br/>
              </>
            }
            {/* END Teotihuacan related*/}
            {(gameResults && gameSlug && !exceptionGames.includes(gameSlug)) &&
              <>
                <GameResultComponent gameData={gameResults} noImageGenerate={noImageGenerate} config={config} />
                <Divider />
              </>
            }
            {/* Unmatched Layout*/}
            {((gameResults && (gameSlug === 'unmatched' || gameSlug === 'dice-throne')) &&
              <>
                <Row gutter={[16, 16]}>
                    {gameResults && gameResults.playersSetup.map((playerData: any, index: number) => (
                        <Col key={`um-player-${playerData.playerNumber}`}
                             xs={12}
                             md={12}
                             lg={8}
                             xl={6}
                             xxl={4}>
                            <UnmatchedPlayerResult key={`um-result-${index}`} playerData={playerData} />
                        </Col>
                    ))}

                    {gameResults && Object.keys(gameResults.commonSetup).length > 0 && (
                        <Col key="999"
                             xs={24}
                             md={12}
                             lg={8}
                             xl={6}
                             xxl={4}>
                            <UnmatchedCommonResult commonData={gameResults.commonSetup} />
                        </Col>
                    )}
                </Row>
                <Divider />
              </>)}

            {/*{Calico Results}*/}
            {gameResults && gameSlug && gameSlug === 'calico' && <CalicoResults gameData={gameResults} config={config} noImageGenerate={noImageGenerate} />}
            {/*{Arnak Results}*/}
            {gameResults && gameSlug && gameSlug === 'lost-ruins-of-arnak' && <ArnakResults gameData={gameResults} config={config} noImageGenerate={noImageGenerate} />}
            {/*{Orleans Results}*/}
            {gameResults && gameSlug && gameSlug === 'orleans' && <OrleansResults gameData={gameResults} config={config} noImageGenerate={noImageGenerate} />}

            <Form
                form={form}
                onFinish={handleSubmit}
                // onFieldsChange={handleFieldsChange}
                initialValues={{
                    players: numberOfPlayers,
                    gameId,
                    expansions: selectedExpansions,
                }}
                layout="vertical"
            >
                {SubmitBtn}

                <Form.Item name="gameId" hidden>
                    <input type="hidden" />
                </Form.Item>

                {numberOfPlayers !== 0 && (
                    <PlayerSetup
                        numberOfPlayers={numberOfPlayers}
                        setNumberOfPlayers={setNumberOfPlayers}
                        playerRange={playerRange}
                    />
                )}

                {mergedColors.length > 0 && (
                    <ColorsSetup colors={mergedColors} game={game} form={form} />
                )}

                <Expansions
                    game={game}
                    selectedExpansions={selectedExpansions}
                    onCheckAllExp={onCheckAllExp}
                    checkAll={checkAll}
                    handleExpansionsCheck={handleExpansionsCheck}
                    config={config}
                />

                <SpecialOptions game={game} />

                {gameSlug === 'spirit-island' && (
                    <SpiritIslandOptions game={game} />
                )}
                {gameSlug === 'teotihuacan-city-of-gods' && (
                    <TeotihuacanOptions game={game} selectedExpansions={selectedExpansions} />
                )}

                <ExcludedItems
                    config={config}
                    randomizedEntities={randomizedEntities}
                    selectedEntities={selectedEntities}
                    handleSelectAllChange={handleSelectAllChange}
                    checkAllStates={checkAllStates}
                    indeterminateStates={indeterminateStates}
                    handleItemChange={handleItemChange}
                    activeCollapseKeys={activeCollapseKeys}
                    handleCollapseChange={handleCollapseChange}
                />

                {SubmitBtn}
            </Form>

            {game?.attributes.image_credit && game?.attributes.image_credit.length > 0 &&
              <>
                <Divider orientation="left" orientationMargin="0"><FileImageOutlined /> Image Credit</Divider>
                <Space direction="vertical">
                    {game?.attributes.image_credit.map(img => {
                        return (
                            <Typography.Link href={img.link} target="_blank" key={`imgcr-${img.id}`}>
                                {img.link}
                            </Typography.Link>
                        )
                    })}
                </Space>
              </>
            }
        </>
    )
}

export default GamePage
