import React from 'react';
import { Link } from 'react-router-dom';
import {Layout, Typography, Menu, MenuProps, Grid} from "antd";
import {CoffeeOutlined, ContactsOutlined, FacebookOutlined, InstagramOutlined, MailOutlined} from "@ant-design/icons";
import {isSmallScreen} from "../utils/myBreakpoints";
const { Footer } = Layout;

type MenuItem = Required<MenuProps>['items'][number];

const items: MenuItem[] = [
    {
        label: (
            <Link to="/about">About</Link>
        ),
        key: 'contact',
        icon: <ContactsOutlined />,
    },
    {
        label: (
            <Typography.Link href="mailto:ttsetupwizard@gmail.com" target="_blank">
                Request a game
            </Typography.Link>
        ),
        key: 'mail',
        icon: <MailOutlined />,
    },
    {
        label: (
            <Typography.Link href="https://www.paypal.com/donate/?hosted_button_id=24UTQ2YND8CS6" target="_blank">
                Support me
            </Typography.Link>
        ),
        key: 'Support',
        icon: <CoffeeOutlined />,
    },
    {
        key: 'insta',
        label: (
            <Typography.Link href="https://www.instagram.com/love.tea.boardgame/" target="_blank">
                <InstagramOutlined style={{fontSize: 20}} />
            </Typography.Link>
        ),
    },
    {
        key: 'fb',
        label: (
            <Typography.Link href="https://www.facebook.com/groups/958020602688119" target="_blank">
                <FacebookOutlined style={{fontSize: 20}} />
            </Typography.Link>
        ),
    },
];
const { useBreakpoint } = Grid;
const BottomBar: React.FC = () => {

    return (
        <Footer>
            <Menu
                mode={isSmallScreen(useBreakpoint()) ? 'vertical' : 'horizontal'}
                items={items}
                selectable={false}
            />
            <Typography>&copy; All Games and art illustrations are the sole property of their publishers. This site is not affiliated in any way with any of the publishers.</Typography>
        </Footer>
    );
};

export default BottomBar;
