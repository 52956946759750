import React from 'react';
import {Game} from "../types/types";
import {Card, Flex} from "antd";
import Meta from "antd/es/card/Meta";
import { useNavigate } from 'react-router-dom';

interface GamesGridProps {
    games: Game[];
}

const GamesGrid: React.FC<GamesGridProps> = ({ games }) => {
    const API_URL = process.env.REACT_APP_MEDIA_URL;
    const navigate = useNavigate();

    return (
        <>
            <Flex wrap="wrap" gap="large" justify="center">
                {games.map((game) => {

                    const publisherNames = game.attributes.publishers.data
                        .map(publisher => publisher.attributes.Name)
                        .join(', ');

                    const handleClick = () => {
                        window.scrollTo({
                            top: 0,
                        })
                        navigate(`/game/${game.attributes.gameSlug}`); // Navigate to GamePage with gameName
                    };

                    return (
                        <Card
                            key={game.id}
                            hoverable
                            onClick={handleClick}
                            cover={<img
                                src={`${API_URL}${game.attributes.Cover.data.attributes.url}`}
                                alt={game.attributes.Cover.data.attributes.alternativeText || game.attributes.game_name}
                                width={246}
                            />}
                            style={{flex: "0 0 246px", width: "246px"}}
                        >
                            <Meta
                                title={game.attributes.game_name}
                                description={`Publishers: ${publisherNames}`}
                            />
                        </Card>
                    )
                })}
            </Flex>
        </>
    );
};

export default GamesGrid;
