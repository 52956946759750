export const parentConfig :any = {
    "hasImages": true,
    "selectedExpansions": false,
    "commonCols": {
        "xs": 12,
        "sm": 12,
        "md": 8,
        "lg": 6,
        "xl": 4
    },
    "playerCols": {
        "xs": 24,
        "sm": 24,
        "md": 24,
        "lg": 24,
        "xl": 24
    }
}
