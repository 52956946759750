import {CommonGameParams, GameResult, SimpleEntity} from "../../types/types";
import {getGameFilter, shuffleArray} from "../../utils/helpers";
import {fetchData} from "../api";
import {dynamicParams} from "../params";

interface PlayerSetup {
    playerNumber :number;
    leader :SimpleEntity[];
    color :string | null;
}
export const duneImperium = async (params :CommonGameParams): Promise<GameResult> => {

    // Id's of game and expansions
    const gameFilter :number[] = getGameFilter(parseInt(params.gameId), params.expansions)

    const rawLeaders :SimpleEntity[] = await fetchData('dune-leaders', dynamicParams(['Name', 'complexity', 'choam_module'], gameFilter, ['image'], [{complexity: {
            $in: params.leaders_complexity,
        }}]));
    const excludeFilterLeaders = rawLeaders.filter(item  => !params["exclude_dune-leaders"]?.includes(item.id) );

    const shuffledLeaders :SimpleEntity[] = !params.choam ? shuffleArray(excludeFilterLeaders.filter(f => !f.attributes.choam_module)) : shuffleArray(excludeFilterLeaders);

    const shuffledColors :string[] | null = params.colors ? shuffleArray(params.colors) : null

    // Initialize arrays to store final selections
    const playersSetup :PlayerSetup[] =
        Array.from({ length: params.players }, (setup, index) =>
            ({
                playerNumber: index + 1,
                leader: shuffledLeaders[index] ? [shuffledLeaders[index]] : [],
                color: shuffledColors ? shuffledColors[index] : null
            }));

    return {
        gameId: params.gameId,
        playersSetup: playersSetup,
        commonSetup: {}
    };
}
