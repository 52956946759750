import React from "react";

const Bymeacoffe: React.FC = () => {

    return (
        <>
            <br />
            <a href="https://www.buymeacoffee.com/ttsetupwizard">
                <img
                    src="https://img.buymeacoffee.com/button-api/?text=For a new game&emoji=🎲&slug=ttsetupwizard&button_colour=FFDD00&font_colour=000000&font_family=Cookie&outline_colour=000000&coffee_colour=ffffff"
                    alt="By me a coffe"
                />
            </a>
        </>
    );
};

export default Bymeacoffe;
