import {CommonGameParams, GameResult, SimpleEntity} from "../../types/types";
import {getGameFilter, shuffleArray} from "../../utils/helpers";
import {fetchData} from "../api";
import {dynamicParams} from "../params";

interface PlayerSetup {
    playerNumber: number;
    UmCharacter: SimpleEntity;
    position: number;
}
export const unmatched = async (params :CommonGameParams): Promise<GameResult> => {

    // Id's of game and expansions
    const gameFilter :number[] = getGameFilter(parseInt(params.gameId), params.expansions)

    const rawCharacters :SimpleEntity[] = await fetchData('unmatched-characters', dynamicParams(['Name'], gameFilter, ['image']));
    const rawMaps :SimpleEntity[] = await fetchData('unmatched-maps', dynamicParams(['Name', 'map_max_players'], gameFilter, ['image', 'game']));

    const shuffledCharacters :SimpleEntity[] = params["exclude_unmatched-characters"] ? shuffleArray(rawCharacters.filter(item  => !params["exclude_unmatched-characters"]?.includes(item.id) )) :  shuffleArray(rawCharacters);
    const filterExcludedMaps :SimpleEntity[] = rawMaps.filter(item  => !params["exclude_unmatched-maps"]?.includes(item.id) );

    const shuffledMaps = shuffleArray(filterExcludedMaps.filter(item  => item.attributes.map_max_players >= params.players ));

    const positionsCount = Array.from({ length: params.players }, (_, i) => i + 1);
    const positions :number[] = shuffleArray(positionsCount);

    const shuffledColors :string[] | null = params.colors ? shuffleArray(params.colors) : null

    // Initialize arrays to store final selections
    const playersSetup :PlayerSetup[] =
        Array.from({ length: params.players }, (setup, index) =>
            ({
                playerNumber: index + 1,
                UmCharacter: shuffledCharacters[index],
                position: positions[index],
                color: shuffledColors ? shuffledColors[index] : null
            }));

    return {
        gameId: params.gameId,
        playersSetup: playersSetup,
        commonSetup: {
            UmMap: shuffledMaps[0]
        }
    };

}
