import {Card, Checkbox, Form, Popover, Radio, Segmented, Slider, Space, Switch,} from "antd";
import React from "react";
import {Game, SimpleEntity} from "../../types/types";
import {CheckOutlined, CloseOutlined, InfoCircleOutlined, QuestionOutlined} from "@ant-design/icons";

interface TeotihuacanOptionsProps {
    game :Game | null;
    selectedExpansions: number[]
}

const TeotihuacanOptions :React.FC<TeotihuacanOptionsProps> = ({ game , selectedExpansions}) => {
    const lppModules = ['teo_m1', 'teo_m2', 'teo_m3', 'teo_m4', 'teo_m5']
    const expModules = ['teo_m6', 'teo_m7', 'teo_m8', 'teo_m9']
    let modules :SimpleEntity[] = []
    if (selectedExpansions.includes(107)) {
        game?.attributes.modules.data.forEach(module => {
            if (lppModules.includes(module.attributes.slug)) {
                modules.push(module)
            }
        })
    }
    if (selectedExpansions.includes(108)) {
        game?.attributes.modules.data.forEach(module => {
            if (expModules.includes(module.attributes.slug)) {
                modules.push(module)
            }
        })
    }
    return  (
        <>
            <Card title="Use Starting Tiles Draft Mode" size="small">
                <Form.Item name="teo_draft_mode">
                    <Switch checkedChildren="Yes" unCheckedChildren="No" defaultChecked={false} />
                </Form.Item>
            </Card>
            <br/>
            {modules.length > 0 &&
                <Card title="Modules" size="small">
                    <Space wrap align="end" style={{maxWidth: 600}}>
                        {modules.map((element :SimpleEntity) =>
                            (
                                <Form.Item name={element.attributes.slug} key={element.attributes.slug} label={element.attributes.Name} initialValue={'yes'} style={{maxWidth: 300}}>
                                    <Segmented<string>
                                        options={[
                                            { label: 'Yes', value: 'yes', icon: <CheckOutlined style={{color: "green"}} /> },
                                            { label: 'No', value: 'no', icon: <CloseOutlined style={{color: "red"}} /> },
                                            { label: 'Maybe', value: 'maybe', icon: <QuestionOutlined style={{color: "orange"}} /> },
                                        ]}
                                    />
                                </Form.Item>
                            )
                        )}
                    </Space>
                </Card>
            }
        </>
    );
}

export default TeotihuacanOptions;
