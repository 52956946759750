import {Checkbox, Divider, Form, Select, Switch} from "antd";
import {PuzzleIcon, PuzzleThreeIcon, PuzzleTwoIcon} from "../../utils/customIcons";
import React from "react";
import {Game} from "../../types/types";

interface SpecialOptionsProps {
    game :Game | null;
}

const SpecialOptions :React.FC<SpecialOptionsProps> = ({ game }) => {
    return (
        <>
            {game?.attributes.special_options?.map((option, index) => {
                switch (option.type) {
                    case "select":
                        return (
                            <div key={`sp-opt-${index}`}>
                                <Divider orientation="left" orientationMargin="0">{option.label}</Divider>
                                <Form.Item key={`sp-options-${option.id}`} name={option.name}>
                                    <Select>
                                        {option.select_options?.map(opt => (
                                            <Select.Option key={`sp-value-${opt.value}`}
                                                           value={opt.value}>{opt.label}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </div>
                        );
                    case "checkbox":
                        // const checkedList = option.select_options.map(opt => opt.value);
                        return (
                            <div key={`sp-opt-${index}`}>
                                <Divider orientation="left" orientationMargin="0">{option.label}</Divider>
                                <Form.Item key={`sp-options-${option.id}`} name={option.name}
                                           initialValue={option.select_options?.map(opt => opt.value)}>
                                    <Checkbox.Group>
                                        {option.select_options?.map(opt => (
                                            <Checkbox key={`sp-option-${opt.value}`} value={opt.value}
                                                      >
                                                {(() => {
                                                    switch (opt.label) {
                                                        case "Low":
                                                            return <PuzzleIcon style={{fontSize: 26}} />;
                                                        case "Medium":
                                                            return <PuzzleTwoIcon style={{fontSize: 26}} />;
                                                        case "High":
                                                            return <PuzzleThreeIcon style={{fontSize: 26}} />;
                                                        case "D1":
                                                        case "D2":
                                                        case "D3":
                                                        case "D4":
                                                        case "D5":
                                                        case "D6":
                                                            return <span style={{padding: '5px 0', display: "inline-block"}}><img src={`/img/dice-complexity/${opt.value}.webp`} alt={opt.label} width={46} height={46} /></span>;
                                                        default:
                                                            return opt.label;
                                                    }
                                                })()}
                                            </Checkbox>
                                        ))}
                                    </Checkbox.Group>
                                </Form.Item>
                            </div>
                        );
                    case "boolean":
                        return (
                            <div key={`sp-opt-${index}`}>
                                <Divider orientation="left" orientationMargin="0">{option.label}</Divider>
                                <Form.Item key={`sp-options-${option.id}`}
                                           name={option.name}
                                           initialValue={option.default_boolean}
                                           extra={option.option_description}
                                >
                                    <Switch checkedChildren="Yes" unCheckedChildren="No" defaultChecked={false} />
                                </Form.Item>
                            </div>
                        );
                    default:
                        return null;
                }
            })}
        </>
    )
}

export default SpecialOptions;
