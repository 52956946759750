import {GameResult, SimpleEntity} from "../types/types";
import React from "react";
import {Card, Flex, Image} from "antd";
import {noImageFound} from "../utils/helpers";
import styles from '../styles/CalicoResults.module.css';
import classNames from "classnames";
import {UserOutlined} from "@ant-design/icons";
import {DTwentyIcon} from "../utils/customIcons";

interface ArnakResultsProps {
    gameData: GameResult;
    config: any;
    noImageGenerate: boolean;
}
const ArnakResults: React.FC<ArnakResultsProps> = ({gameData, config}) => {
    const { playersSetup, commonSetup } = gameData;
    const API_URL = process.env.REACT_APP_MEDIA_URL;
    return (
        <>
            {commonSetup && commonSetup.board.length > 0 && (
                <Card title={commonSetup.board[0].attributes.Name} style={{maxWidth: 548}}>
                    <img
                        src={commonSetup.board[0].attributes.image.data ? `${API_URL}${commonSetup.board[0].attributes.image.data?.attributes.url}` : noImageFound}
                        style={{maxWidth: 500, width: '100%'}}
                        alt={commonSetup.board[0].attributes.Name}
                        width='500'
                    />
                </Card>
            )}
            <br/>
            {commonSetup && commonSetup.temple.length > 0 && (
                <Card title={commonSetup.temple[0].attributes.Name} style={{maxWidth: 548}}>
                    <img
                        src={commonSetup.temple[0].attributes.image.data ? `${API_URL}${commonSetup.temple[0].attributes.image.data?.attributes.url}` : noImageFound}
                        style={{maxWidth: 500, width: '100%'}}
                        alt={commonSetup.temple[0].attributes.Name}
                        width='500'
                    />
                </Card>
            )}
            <br/>
            {playersSetup && (
                <>
                {playersSetup.map((playerData) => (
                    <div key={`player-${playerData.playerNumber}`}>
                        <Card
                            style={{maxWidth: 548}}
                            title={(
                                <>
                                    <UserOutlined /> Player {playerData.playerNumber}
                                    {playerData.color && (<>, Color: <DTwentyIcon
                                        style={{color: `${playerData.color}`, fontSize: '20px'}} /></>)}
                                </>
                            )}>
                            {playerData.leader.length > 0 && playerData.leader.map((el: SimpleEntity) => (
                                <div key={`leader-${playerData.playerNumber}`}>
                                    <h3 style={{margin: 0}}>{el.attributes.Name}</h3>
                                    <img
                                        key={`designGoals-${el.id}-${playerData.playerNumber}`}
                                        src={el.attributes.image.data ? `${API_URL}${el.attributes.image.data?.attributes.url}` : noImageFound}
                                        alt={el.attributes.Name}
                                        style={{maxWidth: 500, width: '100%'}}
                                    />
                                </div>
                                ))
                            }
                        </Card>
                        <br/>
                    </div>
                ))}
                </>
            )}
        </>
    )
}

export default ArnakResults
