// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CalicoResults_calicoCatCard__idgj- {
    position: relative;
    padding-bottom: 77px;
    max-width: 350px;
}
.CalicoResults_calicoPath__gDWPl {
    position: absolute;
    width: 100px;
    bottom: 24px;
}
.CalicoResults_calicoPathLeft__0FzqV {
    left: 64px;
}
.CalicoResults_calicoPathRight__r0MYd {
    right: 64px;
}
.CalicoResults_calicoDesignGoal__6TJGG {
    max-width: 150px;
    width: 48%;
}
`, "",{"version":3,"sources":["webpack://./src/styles/CalicoResults.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,oBAAoB;IACpB,gBAAgB;AACpB;AACA;IACI,kBAAkB;IAClB,YAAY;IACZ,YAAY;AAChB;AACA;IACI,UAAU;AACd;AACA;IACI,WAAW;AACf;AACA;IACI,gBAAgB;IAChB,UAAU;AACd","sourcesContent":[".calicoCatCard {\n    position: relative;\n    padding-bottom: 77px;\n    max-width: 350px;\n}\n.calicoPath {\n    position: absolute;\n    width: 100px;\n    bottom: 24px;\n}\n.calicoPathLeft {\n    left: 64px;\n}\n.calicoPathRight {\n    right: 64px;\n}\n.calicoDesignGoal {\n    max-width: 150px;\n    width: 48%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"calicoCatCard": `CalicoResults_calicoCatCard__idgj-`,
	"calicoPath": `CalicoResults_calicoPath__gDWPl`,
	"calicoPathLeft": `CalicoResults_calicoPathLeft__0FzqV`,
	"calicoPathRight": `CalicoResults_calicoPathRight__r0MYd`,
	"calicoDesignGoal": `CalicoResults_calicoDesignGoal__6TJGG`
};
export default ___CSS_LOADER_EXPORT___;
