import React, {useState} from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { GamesProvider } from './context/GamesContext';
import HomePage from './pages/HomePage';
import GamePage from './pages/GamePage';
import NavBar from './components/NavBar';
import {ConfigProvider, FloatButton, Layout, theme} from "antd";
import BottomBar from "./components/footer";
import AboutPage from "./pages/AboutPage";
import {CoffeeOutlined, CustomerServiceOutlined} from "@ant-design/icons";
import Bymeacoffe from "./components/Bymeacoffe";
import Bymeacoffesmall from "./components/Bymeacoffesmall";

const { Content } = Layout;

function App() {
    const [searchQuery, setSearchQuery] = useState("");
    const handleSearch = (query: string) => {
        setSearchQuery(query);
    };

    return (
        <GamesProvider> {/* Wrap the Router with GamesProvider */}
            <Router>
                <ConfigProvider
                    theme={{
                        "token": {
                            "colorBgBase": "#282828"
                        },
                        algorithm: theme.darkAlgorithm,
                        components: {
                            Layout: {
                                headerPadding: "0 24px"
                            },
                            Collapse: {
                                paddingSM: 0,
                                headerPadding: '12px 16px'
                            },
                            Slider: {
                                trackBg: "rgba(255, 255, 255, 0.85)",
                                trackHoverBg: "rgba(255, 255, 255, 1)",
                                handleColor: "rgba(255, 255, 255, 0.85)",
                                handleActiveColor: "rgba(255, 255, 255, 1)",
                            },
                        },
                    }}
                >
                    <FloatButton
                        style={{ width: 109, borderRadius: 10, insetInlineEnd: 10 }}
                        description={<Bymeacoffesmall />}
                        onClick={() => window.open('https://www.buymeacoffee.com/ttsetupwizard', '_blank')}
                    />
                    <Layout style={{ minHeight: '100vh' }}>
                        <NavBar onSearch={handleSearch} />
                        <Content style={{ padding: '0 24px' }}>
                            <Routes>
                                <Route path="/" element={<HomePage searchQuery={searchQuery} />} />
                                <Route path="/game/:gameSlug" element={<GamePage />} />
                                <Route path="/about" element={<AboutPage />} />
                            </Routes>
                        </Content>
                        <BottomBar />
                    </Layout>
                </ConfigProvider>
            </Router>
        </GamesProvider>
    );
}

export default App;
