import {Divider, Form, Grid, Radio, Space} from "antd";
import {TeamOutlined} from "@ant-design/icons";
import {isSmallScreen} from "../../utils/myBreakpoints";
import React from "react";

const { useBreakpoint } = Grid;

interface PlayerSetupProps {
    numberOfPlayers :number | undefined;
    setNumberOfPlayers :(value :number | undefined) => void | undefined;
    playerRange: number[]
}

const PlayerSetup :React.FC<PlayerSetupProps> = ({ numberOfPlayers, setNumberOfPlayers, playerRange }) => {

    const breakpoints = useBreakpoint();
    return (
        <>
            <Divider orientation="left" orientationMargin="0"><TeamOutlined /> Number of Players</Divider>
            <Form.Item name="players">
                <Radio.Group onChange={(e) => setNumberOfPlayers(e.target.value)} value={numberOfPlayers}
                             buttonStyle="solid">
                    {breakpoints.md && playerRange.map(i => (
                        <Radio.Button key={`player-count-${i}`} value={i}>
                            {i} Player{i > 1 ? 's' : ''}
                        </Radio.Button>
                    ))}
                    {isSmallScreen(breakpoints) &&
                      <Space wrap>
                          {playerRange.map(i => (
                              <Radio.Button key={`player-count-${i}`} value={i}>
                                  {i} Player{i > 1 ? 's' : ''}
                              </Radio.Button>
                          ))}
                      </Space>
                    }
                </Radio.Group>
            </Form.Item>
        </>
)}

export default PlayerSetup;
