import {Alert, Checkbox, CheckboxProps, Divider, Form, FormInstance} from "antd";
import React, {useEffect, useState} from "react";
import {Game, GameColor} from "../../types/types";
import {DTwentyIcon} from "../../utils/customIcons";
import {BgColorsOutlined} from "@ant-design/icons";

interface ColorSetupProps {
    colors: GameColor[];
    game : Game | null;
    form :FormInstance
}
type CheckboxValueType = string;

const ColorSetup :React.FC<ColorSetupProps> = ({ colors, game, form  }) => {
    const initColors = game?.id === 9 || game?.id === 86 ? ['#1668dc', '#d7d001'] : [];

    const allColors :string[] = colors.map((color: GameColor) => color.attributes.Color)

    const [selectedColors, setSelectedColors] = useState<CheckboxValueType[]>(initColors);
    const [checkAll, setCheckAll] = useState(false);
    const [indeterminate, setIndeterminate] = useState(false);

    useEffect(() => {
        setIndeterminate(!!selectedColors.length && selectedColors.length < colors.length);
        setCheckAll(selectedColors.length === colors.length);
    }, [selectedColors, colors.length]);

    const onCheckAllChange: CheckboxProps['onChange'] = (e) => {
        const checked :boolean = e.target.checked;
        const newSelectedColors :string[] = checked ? allColors : [];
        setSelectedColors(newSelectedColors);
        form.setFieldsValue({ colors: newSelectedColors });
    };

    const onGroupChange = (checkedValues: CheckboxValueType[]) => {
        setSelectedColors(checkedValues);
    };
    return (
        <>
            <Divider orientation="left" orientationMargin="0"><BgColorsOutlined /> Select Players Colors (optional)</Divider>
            {game?.attributes.colors_msg && (
                <Alert
                    message={game?.attributes.colors_msg}
                    type="info" showIcon
                    style={{marginBottom: 15}}
                />
            )}

            <Form.Item name="colors" initialValue={initColors} label={(
                <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
                    Select All Colors
                </Checkbox>
            )}>
                <Checkbox.Group value={selectedColors} onChange={onGroupChange}>
                    {colors.map((color: GameColor) => (
                        <Checkbox key={color.attributes.Color} value={color.attributes.Color}>
                            <DTwentyIcon
                                style={{ fontSize: '50px', color: `${color.attributes.Color}` }}
                            />
                        </Checkbox>
                    ))}
                </Checkbox.Group>
            </Form.Item>
        </>
    )
}
export default ColorSetup;
