import {GameResult, RemovedGood} from "../types/types";
import React from "react";
import stylesGl from '../styles/global.module.css';
import styles from '../styles/OrleanResults.module.css';
import classNames from "classnames";
import {Card} from "antd";
import {UserOutlined} from "@ant-design/icons";
import {DTwentyIcon} from "../utils/customIcons";

interface OrleansResultsProps {
    gameData: GameResult;
    config: any;
    noImageGenerate: boolean;
}
const OrleanResults: React.FC<OrleansResultsProps> = ({gameData, config}) => {
    const { playersSetup, commonSetup } = gameData;
    return (
        <>
            <div className={stylesGl.textSection}>
            {playersSetup.length === 2 &&
                <>
                  <p><strong>Remove 4 (6 if own 5th player exp.) each of the neutral “Farmer”, “Boatman”, “Craftsman”, and “Trader” Character Tiles from the game.</strong></p>
                  <p><strong>Remove 6 (9 if own 5th player exp.) each of the “Knight”, “Scholar”, and “Monk” Character Tiles from the game.</strong></p>
                  <br/>
                </>
            }
            {playersSetup.length === 3 &&
                <>
                  <p><strong>Remove 2 (4 if own 5th player exp.) each of the neutral “Farmer”, “Boatman”, “Craftsman”, and “Trader” Character Tiles from the game.</strong></p>
                  <p><strong>Remove 3 (6 if own 5th player exp.) each of the “Knight”, “Scholar”, and “Monk” Character Tiles from the game.</strong></p>
                  <br/>
                </>
            }
            {playersSetup.length === 4 &&
                <>
                  <h3>If you own 5th player expansion.</h3>
                  <p><strong>Remove 2 each of the neutral “Farmer”, “Boatman”, “Craftsman”, and “Trader” Character Tiles from the game.</strong></p>
                  <p><strong>Remove 3 each of the “Knight”, “Scholar”, and “Monk” Character Tiles from the game.</strong></p>
                  <br/>
                </>
            }
            {commonSetup && commonSetup.removedGoods.length > 0 && (
                <>
                    <h3>Remove these Goods from the game:</h3>
                    <ul className={styles.goodsRemoveList}>
                        {commonSetup.removedGoods.map((good :RemovedGood , index :number) => (
                            <li key={`${good.name}-index`}>
                                <img src={`/img/orleans/${good.name}.webp`} width="50" height="50" alt={good.name} />
                                <span style={{textTransform: "capitalize"}}>{good.name}</span> - {good.count}
                            </li>
                        ))}
                    </ul>
                </>
            )}
            </div>
            {/* Map */}
            <>
                <h3>Place Goods on the map as shown below</h3>
                <div className={styles.mapWrapper}>
                    <img src={`/img/orleans/orleans-map.webp`} width="700" height="827" alt="Orleans Map" />
                    {commonSetup && commonSetup.selectedGoods.length > 0 && commonSetup.selectedGoods.map((g: any, index: number) => (
                        <div className={classNames(styles.good, styles[`good_${index}`])} key={index}>
                            <img src={`/img/orleans/${g}.webp`} width="50" height="50" alt={g} />
                        </div>
                    ))}
                </div>
            </>
            {playersSetup.length > 0 &&
              <>
                <Card title="Players Colors" >
                    {playersSetup.map((playerData) => (
                      <div className={styles.orPlayersData}  key={`player-${playerData.playerNumber}`}>
                          <UserOutlined /> Player {playerData.playerNumber}
                          {playerData.color && (
                              <>
                                  , Color:
                                  <DTwentyIcon style={{color: `${playerData.color}`, fontSize: '50px'}} />
                              </>
                          )}
                          {!playerData.color && (
                              <>
                                  , Color: Not selected
                              </>
                          )}
                      </div>
                    ))}
                </Card>
                <br/>
              </>
            }
        </>
    )
}

export default OrleanResults
