import {CommonGameParams, GameResult, SimpleEntity, TeoModules} from "../../types/types"
import {getGameFilter, getRandomNumberInRange, shuffleArray} from "../../utils/helpers"
import {fetchData} from "../api"
import {dynamicParams} from "../params"
import {BlocksContent} from "@strapi/blocks-react-renderer";

interface PlayerSetup {
    playerNumber :number
    teoPriests: SimpleEntity[]
    teoStartingTiles: SimpleEntity[]
    color :string | null
}

async function selectBoards(params :CommonGameParams, gameFilter :number[], modules: TeoModules) {
    let selectedBoards :SimpleEntity[] = await fetchData('teotihuacan-action-boards', dynamicParams(['Name'], gameFilter, ['image']))
    let boardsToRemove :number[] = []

    // Architecture module M4
    if (modules.teo_m4) {
        boardsToRemove.push(6)
    } else {
        boardsToRemove.push(7)
    }

    // Development module M5
    if (!modules.teo_m5) {
        boardsToRemove.push(8)
    }

    // Obsidian module M6
    if (modules.teo_m6) {
        boardsToRemove.push(1, 2, 3)
    } else {
        boardsToRemove.push(9, 10, 11)
    }

    // Mansion module M7
    if (!modules.teo_m7) {
        boardsToRemove.push(12)
    }

    // Height of Development M2 and Expanding Empire M9
    if (modules.teo_m2 || modules.teo_m9) {
        boardsToRemove.push(5);
    }

    if (modules.teo_m2 && modules.teo_m9) {
        boardsToRemove.push(13, 14)
    } else if (modules.teo_m2) {
        boardsToRemove.push(14, 15)
    } else if (modules.teo_m9) {
        boardsToRemove.push(13, 15)
    } else if (!modules.teo_m2 && !modules.teo_m9) {
        boardsToRemove.push(13, 14, 15)
    }

    selectedBoards = selectedBoards.filter(board => !boardsToRemove.includes(board.id))

    return selectedBoards
}

function updateModulesInDescription(modules :string[], description :any) {
    modules.forEach(module => {
        switch (module) {
            case 'teo_m1':
                description[1].children.push({
                    "type": "list-item",
                    "children": [
                        {
                            "text": "Priests and Priestesses (M1)",
                            "type": "text"
                        }
                    ]
                })
                break;
            case 'teo_m2':
                description[1].children.push({
                    "type": "list-item",
                    "children": [
                        {
                            "text": "Height of Development (M2)",
                            "type": "text"
                        }
                    ]
                })
                break;
            case 'teo_m3':
                description[1].children.push({
                    "type": "list-item",
                    "children": [
                        {
                            "text": "Seasons of Progress (M3). Three Season Tiles will be blurred, you can tap/click on it to unblur one by one. First at the start of the game, second and third after first and second Eclipses accordingly.",
                            "type": "text"
                        }
                    ]
                })
                break;
            case 'teo_m4':
                description[1].children.push({
                    "type": "list-item",
                    "children": [
                        {
                            "text": "Architecture (M4)",
                            "type": "text"
                        }
                    ]
                })
                break;
            case 'teo_m5':
                description[1].children.push({
                    "type": "list-item",
                    "children": [
                        {
                            "type": "paragraph",
                            "children": [
                                {
                                    "text": "Development (M5). Board 8 will be shuffled with other boards as mentioned in the Rules Variant from the Deluxe Core Rules: During setup, you may choose to shuffle the Development (8) Action Board with the other Action Boards. Note that doing so may result in a significantly easier or more challenging Game Board layout.",
                                    "type": "text"
                                }
                            ]
                        },
                        {
                            "type": "paragraph",
                            "children": [
                                {
                                    "text": "If you'd prefer to use these board in regular (printed) positions, simply ignore it in the randomized results.",
                                    "type": "text"
                                }
                            ]
                        }
                    ]
                })
                break;
            case 'teo_m6':
                description[1].children.push({
                    "type": "list-item",
                    "children": [
                        {
                            "text": "Obsidian (M6)",
                            "type": "text"
                        }
                    ]
                })
                break;
            case 'teo_m7':
                description[1].children.push({
                    "type": "list-item",
                    "children": [
                        {
                            "type": "paragraph",
                            "children": [
                                {
                                    "text": "Mansion (M7). Board 1 will be shuffled with other boards as mentioned in the Rules Variant from the Deluxe Core Rules: During setup, you may choose to shuffle the Mansion (1) Action Board with the other Action Boards. Note that doing so may result in a significantly easier or more challenging Game Board layout.",
                                    "type": "text"
                                }
                            ]
                        },
                        {
                            "type": "paragraph",
                            "children": [
                                {
                                    "text": "If you'd prefer to use these board in regular (printed) positions, simply ignore it in the randomized results.",
                                    "type": "text"
                                }
                            ]
                        }
                    ]
                })
                break;
            case 'teo_m8':
                description[1].children.push({
                    "type": "list-item",
                    "children": [
                        {
                            "text": "Altars and Shamans (M8)",
                            "type": "text"
                        }
                    ]
                })
                break;
            case 'teo_m9':
                description[1].children.push({
                    "type": "list-item",
                    "children": [
                        {
                            "text": "Expanding the Empire (M9)",
                            "type": "text"
                        }
                    ]
                })
                break;
        }
    })

    return description;
}
export const teotihuacan = async (params :CommonGameParams): Promise<GameResult> => {

    let generalDescription :BlocksContent = [
        {
            children: [
                {
                    text: "Used Modules:",
                    type: "text"
                }
            ],
            type: "paragraph"
        },
        {
            "type": "list",
            "format": "unordered",
            "children": []
        }
    ]

    // Id's of game and expansions
    const gameFilter :number[] = getGameFilter(parseInt(params.gameId), params.expansions)
    let modules :TeoModules = {
        teo_m1: params.teo_m1 === 'yes' || (params.teo_m1 === 'maybe' && getRandomNumberInRange(0, 1) === 1),
        teo_m2: params.teo_m2 === 'yes' || (params.teo_m2 === 'maybe' && getRandomNumberInRange(0, 1) === 1),
        teo_m3: params.teo_m3 === 'yes' || (params.teo_m3 === 'maybe' && getRandomNumberInRange(0, 1) === 1),
        teo_m4: params.teo_m4 === 'yes' || (params.teo_m4 === 'maybe' && getRandomNumberInRange(0, 1) === 1),
        teo_m5: params.teo_m5 === 'yes' || (params.teo_m5 === 'maybe' && getRandomNumberInRange(0, 1) === 1),
        teo_m6: params.teo_m6 === 'yes' || (params.teo_m6 === 'maybe' && getRandomNumberInRange(0, 1) === 1),
        teo_m7: params.teo_m7 === 'yes' || (params.teo_m7 === 'maybe' && getRandomNumberInRange(0, 1) === 1),
        teo_m8: params.teo_m8 === 'yes' || (params.teo_m8 === 'maybe' && getRandomNumberInRange(0, 1) === 1),
        teo_m9: params.teo_m9 === 'yes' || (params.teo_m9 === 'maybe' && getRandomNumberInRange(0, 1) === 1),
    }
    if (modules.teo_m7 || modules.teo_m8 || modules.teo_m9) {
        modules.teo_m6 = true;
    }

    const selectedModules :string[] = Object.keys(modules).filter(key => modules[key as keyof TeoModules]);
    const moduleFilter = {
        $or: [
            {
                modules: {slug: {$in: selectedModules}}
            },
            {
                modules: {$null: true}
            }
        ]
    }
    generalDescription = updateModulesInDescription(selectedModules, generalDescription)

    const shuffledColors :string[] | null = params.colors ? shuffleArray(params.colors) : null
    // Initialize arrays to store final selections
    const playersSetup :PlayerSetup[] =
        Array.from({ length: params.players }, (setup, index) =>
            ({
                playerNumber: index + 1,
                color: shuffledColors ? shuffledColors[index] : null,
                teoPriests: [],
                teoStartingTiles: [],
            }))

    const selectedActionBoards :SimpleEntity[] = await selectBoards(params, gameFilter, modules)
    const shuffledActionBoards :SimpleEntity[] = shuffleArray(selectedActionBoards)

    // Temple bonuses
    const rawTempleBonuses : SimpleEntity[] = await fetchData('teotihuacan-temple-bonus-tiles', dynamicParams(['Name', 'description', 'hide_description'], gameFilter, ['image']))
    const shuffledTempleBonuses :SimpleEntity[] = params["exclude_teotihuacan-temple-bonus-tiles"] ? shuffleArray(rawTempleBonuses.filter(item  => !params["exclude_teotihuacan-temple-bonus-tiles"]?.includes(item.id) )) : shuffleArray(rawTempleBonuses)

    // Royal Tiles
    const rawRoyalTiles : SimpleEntity[] = await fetchData('teotihuacan-royal-tiles', dynamicParams(['Name', 'description', 'rt_category', 'hide_description'], gameFilter, ['image'], [moduleFilter] ))
    const shuffledRoyalTiles :SimpleEntity[] = params["exclude_teotihuacan-royal-tiles"] ? shuffleArray(rawRoyalTiles.filter(item  => !params["exclude_teotihuacan-royal-tiles"]?.includes(item.id) )) : shuffleArray(rawRoyalTiles)

    // Technology Tiles
    const rawTechnologyTiles : SimpleEntity[] = await fetchData('teotihuacan-technology-tiles', dynamicParams(['Name', 'description', 'teo_technology_cost', 'hide_description'], gameFilter, ['image'], [moduleFilter]))
    const shuffledTechnologyTiles :SimpleEntity[] = params["exclude_teotihuacan-technology-tiles"] ? shuffleArray(rawTechnologyTiles.filter(item  => !params["exclude_teotihuacan-technology-tiles"]?.includes(item.id) )) : shuffleArray(rawTechnologyTiles)

    const selectedTechnology :SimpleEntity[] = shuffledTechnologyTiles.slice(0, 6).sort((a, b) => {
        if (a.attributes.Name < b.attributes.Name) {
            return -1;
        }
        if (a.attributes.Name > b.attributes.Name) {
            return 1;
        }
        return 0;
    })


    // Started Tiles
    const rawStartingTiles : SimpleEntity[] = await fetchData('teotihuacan-starting-tiles', dynamicParams(['Name', 'description', 'hide_description'], gameFilter, ['image'], [moduleFilter] ))
    const shuffledStartingTiles :SimpleEntity[] = params["exclude_teotihuacan-starting-tiles"] ? shuffleArray(rawStartingTiles.filter(item  => !params["exclude_teotihuacan-starting-tiles"]?.includes(item.id) )) : shuffleArray(rawStartingTiles)

    let startingTilesDraft :SimpleEntity[] = []
    if(!params.teo_draft_mode) {
        shuffledStartingTiles.forEach((priest :SimpleEntity, index :number) => {
            const playerIndex = index % params.players;
            if (playersSetup[playerIndex].teoStartingTiles.length < 4) {
                playersSetup[playerIndex].teoStartingTiles.push(priest);
            }
        });
    } else {
        switch (params.players) {
            case 2:
                startingTilesDraft = shuffledStartingTiles.slice(0, 6)
                break;
            case 3:
                startingTilesDraft = shuffledStartingTiles.slice(0, 8)
                break;
            case 4:
                startingTilesDraft = shuffledStartingTiles.slice(0, 10)
                break;
            case 5:
                startingTilesDraft = shuffledStartingTiles.slice(0, 12)
                break;
        }
    }

    // M1 Priest and Priestess
    if (modules.teo_m1) {
        const rawPriests: SimpleEntity[] = await fetchData('teotihuacan-priests-and-priestesses', dynamicParams(['Name'], gameFilter, ['image'], [moduleFilter]))

        const shuffledPriests: SimpleEntity[] = params["exclude_teotihuacan-priests-and-priestesses"] ? shuffleArray(rawPriests.filter(item => !params["exclude_teotihuacan-priests-and-priestesses"]?.includes(item.id))) : shuffleArray(rawPriests)

        shuffledPriests.forEach((priest :SimpleEntity, index :number) => {
            const playerIndex = index % params.players;
            if (playersSetup[playerIndex].teoPriests.length < 2) {
                playersSetup[playerIndex].teoPriests.push(priest);
            }
        });
    }

    //M3 Seasons of Progress
    let selectedSeasonCards  :SimpleEntity[] = []
    if (modules.teo_m3) {
        const rawSeasonCards : SimpleEntity[] = await fetchData('teotihuacan-seasons', dynamicParams(['Name', 'description', 'hide_description'], gameFilter, ['image'], [moduleFilter]))
        const shuffledSeasonCards :SimpleEntity[] = params["exclude_teotihuacan-seasons"] ? shuffleArray(rawSeasonCards.filter(item  => !params["exclude_teotihuacan-seasons"]?.includes(item.id) )) : shuffleArray(rawSeasonCards)
        selectedSeasonCards = shuffledSeasonCards.slice(0, 3)
    }

    // M8 Altar Tiles
    let shuffledAltarTiles :SimpleEntity[] = []
    if (modules.teo_m8) {
        const rawAltarTiles : SimpleEntity[] = await fetchData('teotihuacan-altar-tiles', dynamicParams(['Name', 'description', 'hide_description'], gameFilter, ['image']))
        shuffledAltarTiles = shuffleArray(rawAltarTiles)
    }

    return {
        gameId: params.gameId,
        playersSetup: playersSetup,
        commonSetup: {
            teoActionBoards: shuffledActionBoards,
            teoTempleBonuses: modules.teo_m2 ? shuffledTempleBonuses.slice(0, 4) : shuffledTempleBonuses.slice(0, 3),
            teoRoyalTiles: shuffledRoyalTiles.length > 0 ? [
                shuffledRoyalTiles?.filter(tile => tile.attributes.rt_category === 'A')[0],
                shuffledRoyalTiles?.filter(tile => tile.attributes.rt_category === 'B')[0],
                shuffledRoyalTiles?.filter(tile => tile.attributes.rt_category === 'C')[0],
            ] : [],
            teoTechnology: selectedTechnology,
            teoSeasons: selectedSeasonCards,
            teoAltar: shuffledAltarTiles,
            teoStartingDraft: startingTilesDraft,
        },
        generalDescription: generalDescription,
    }
}
export const onDrawNeutralDices = async (params :any) => {
//{gameId: gameId, expansions: selectedExpansions, usedDiceCards: usedDiceCards, player: players}
    // Id's of game and expansions
    const gameFilter :number[] = getGameFilter(parseInt(params.gameId), params.expansions)
    const diceCardsFilters = [
        {
            Name: {
                $notIn: params.usedDiceCards,
            }
        },
        {
            players_number: {
                $eq: params.players,
            }
        }
    ]
    const rawDiceCards : SimpleEntity[] = await fetchData('teotihuacan-neutral-dices', dynamicParams(['Name'], gameFilter, ['image'], diceCardsFilters))
    return rawDiceCards[Math.floor(Math.random()*rawDiceCards.length)];
}
export const onDrawTechnology = async (params :any) => {
    const gameFilter :number[] = getGameFilter(parseInt(params.gameId), params.expansions)
    const usedTechnology :number[] = []
    params.usedTechnologies.forEach((el :SimpleEntity) => {
        usedTechnology.push(el.id)
    })
    const filters = [
        {
            id: {
                $notIn: usedTechnology,
            }
        }
    ]
    const rawTech : SimpleEntity[] = await fetchData('teotihuacan-technology-tiles', dynamicParams(['Name'], gameFilter, ['image'], filters))
    return shuffleArray(rawTech).slice(0, 2)
}
