import {fetchData} from "../api";
import {TapestryGameParams} from "../../types/tapestry";
import {getGameFilter, shuffleArray} from "../../utils/helpers";
import {GameResult, SimpleEntity} from "../../types/types";
import {dynamicParams} from "../params";

interface PlayerSetup {
    civilizations: SimpleEntity[];
    cityMats: (SimpleEntity | undefined)[];
    color: string;
}

export const tapestry =  async (params :TapestryGameParams): Promise<GameResult> => {

    // Id's of game and expansions
    const gameFilter :number[] = getGameFilter(parseInt(params.gameId), params.expansions)

    // Fetch data
    const rawCivilizations :SimpleEntity[] = await fetchData('civilizations', dynamicParams(['Name', 'Complexity'], gameFilter, ['image'], [{Complexity: {
            $in: params.civ_complexity,
        }}]));
    const rawCityMats :SimpleEntity[] = await fetchData('capital-city-mats', dynamicParams(['Type', 'PairId', 'Name'], gameFilter, ['image']));
    const rawLandmarkCards :SimpleEntity[] = await fetchData('landmark-cards', dynamicParams(['Name'], gameFilter, ['image']));

    // Shuffle data to randomize selections
    const shuffledCivilizations :SimpleEntity[] = params.exclude_civilizations ? shuffleArray(rawCivilizations.filter(item  => !params.exclude_civilizations.includes(item.id) )) : shuffleArray(rawCivilizations);
    const shuffledCityMats :SimpleEntity[] = params["exclude_capital-city-mats"] ? shuffleArray(rawCityMats.filter(item  => !params["exclude_capital-city-mats"].includes(item.id) )) : shuffleArray(rawCityMats);
    const shuffledLandmarkCards :SimpleEntity[] = params["exclude_landmark-cards"] ? shuffleArray(rawLandmarkCards.filter(item  => !params["exclude_landmark-cards"].includes(item.id) )) : shuffleArray(rawLandmarkCards);

    // Initialize arrays to store final selections
    let playersSetup :PlayerSetup[] =
        Array.from({ length: params.players }, () =>
            ({
                civilizations: [],
                cityMats: [],
                color: '',
            }));

    // Assign two unique civilizations per player
    shuffledCivilizations.forEach((civ :SimpleEntity, index :number) => {
        const playerIndex = index % params.players;
        if (playersSetup[playerIndex].civilizations.length < 2) {
            playersSetup[playerIndex].civilizations.push(civ);
        }
    });

    // Assign city mats
    playersSetup = playersSetup.map((setup :PlayerSetup, index :number) => {
        if (params.players <= 3) {
            // Find matching PairId mats
            const basicMats :SimpleEntity[] = shuffledCityMats.filter(mat => mat.attributes.Type === 'basic');
            const advancedMats :SimpleEntity[] = shuffledCityMats.filter(mat => mat.attributes.Type === 'advanced');
            return {
                ...setup,
                cityMats: [
                    ...basicMats.filter(mat => mat.attributes.PairId === (index % 3) + 1).slice(0, 2),
                    ...advancedMats.slice(index, index + 1),
                ],
            };
        } else {
            // Each player gets one basic and one advanced mat
            const advancedMats :SimpleEntity[] = shuffledCityMats.filter(mat => mat.attributes.Type === 'advanced');
            return {
                ...setup,
                cityMats: [
                    ...shuffledCityMats.filter(mat => mat.attributes.Type === 'basic').slice(index, index + 1),
                    ...advancedMats.slice(index, index + 1),
                ],
            };
        }
    });

    if(params.colors) {
        const shuffledColors :string[] = shuffleArray(params.colors)
        playersSetup = playersSetup.map((setup :PlayerSetup, index :number) => {
            return {
                ...setup,
                color: shuffledColors[index]
            }
        })
    }

    return {
        gameId: params.gameId,
        playersSetup: playersSetup.map((setup, index) => ({
            playerNumber: index + 1,
            civilizations: setup.civilizations,
            cityMats: setup.cityMats,
            color: setup.color,
        })),
        commonSetup: {
            landmarkCards: shuffledLandmarkCards.length > 0 ? shuffledLandmarkCards.slice(0, params.players + 1) : []
        }
    };
}

export const onDrawCivilization = async (params :any) => {
//{gameId: gameId, expansions: selectedExpansions, usedCivs: civilizationsInUse}
    // Id's of game and expansions
    const gameFilter :number[] = getGameFilter(parseInt(params.gameId), params.expansions)
    // API params to get civilizations
    const civParams = {
        fields: ['Name', 'Complexity'],
        filters: {
            game: {
                id: {
                    $in: gameFilter,
                }
            },
            Name: {
                $notIn: params.usedCivs,
            }
        },
        pagination: {
            pageSize: 100,
        },
    };
    const rawCivilizations :SimpleEntity[] = await fetchData('civilizations', civParams);
    return rawCivilizations[Math.floor(Math.random()*rawCivilizations.length)];
}
