interface DynamicParams {
    filters: {
        game: {
            id: {
                $in: number[];
            };
        };
    };
    pagination: {
        pageSize: number;
    };
    [key: string]: any; // This allows for additional dynamic fields
}
export const nameOnlyParams = (gameFilter :number[]) => {
    return {
        fields: ['Name'],
        filters: {
            game: {
                id: {
                    $in: gameFilter,
                }
            }
        },
        pagination: {
            pageSize: 100,
        }
    ,
    }
};
export const dynamicParams = (fields: string[], gameFilter: number[], populate?: string[], filters?: any[]): DynamicParams => {
    // Initialize the params object
    let params: DynamicParams = {
        filters: {
            game: {
                id: {
                    $in: gameFilter,
                },
            },
        },
        pagination: {
            pageSize: 100,
        },
    };

    if (fields.length > 0) {
        params["fields"] = fields;
    }

    if(populate && populate.length > 0) {
        populate.forEach(field => {
            switch (field) {
                case "image":
                    params["populate"] = {
                        "image": {
                            fields: ['name', 'alternativeText', 'caption', 'width', 'height', 'url']
                        }
                    }
                    break;
                case "game":
                    params["populate"] = {
                        ...params["populate"],
                        "game": {
                            fields: ['game_name']
                        }
                    }
                    break;
                case "module":
                    params["populate"] = {
                        ...params["populate"],
                        "module": {
                            fields: ['Name', 'slug']
                        }
                    }
                    break;
                case "modules":
                    params["populate"] = {
                        ...params["populate"],
                        "modules": {
                            fields: ['Name', 'slug']
                        }
                    }
                    break;
                case "scythe_player_mat":
                    params["populate"] = {
                        ...params["populate"],
                        "scythe_player_mat": {
                            fields: ['id']
                        }
                    }
                    break;
                case "scythe_tesla_mech_mod_tiles":
                    params["populate"] = {
                        ...params["populate"],
                        "scythe_tesla_mech_mod_tiles": {
                            fields: ['Name', 'NoAutoma'],
                            populate: {
                                "image": {
                                    fields: ['name', 'alternativeText', 'caption', 'width', 'height', 'url']
                                }
                            }
                        }
                    }
                    break;
                case "no_compatible_with":
                    params["populate"] = {
                        ...params["populate"],
                        "no_compatible_with": {
                            fields: ['id'],
                        }
                    }
                    break;
                case "conjoined_boards":
                    params["populate"] = {
                        ...params["populate"],
                        "conjoined_boards": {
                            fields: ['id'],
                        }
                    }
                    break;
                case "spirit_island_spirit":
                    params["populate"] = {
                        ...params["populate"],
                        "spirit_island_spirit": {
                            fields: ['id'],
                        }
                    }
                    break;
                case 'siLevels':
                    params["populate"] = {
                        ...params["populate"],
                        "Levels": {
                            fields: ['Level', 'Difficulty'],
                        }
                    }
                    break;
                default:
                    break;
            }
        })
    }

    if(filters) {
        filters.forEach(filter => {
            params["filters"] = {
                ...params["filters"],
                ...filter
            };
        })
    }
    return params;
};
