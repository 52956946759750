import { CSSProperties } from "react";
import { Grid } from "antd";

type StylesType = {
    HeaderComponent: CSSProperties;
};

const { useBreakpoint } = Grid;

function Styles(): StylesType {
    const breakpoints = useBreakpoint();

    const HeaderComponent: CSSProperties = (() => {

        const common = {
            height: "auto",
            minHeight: "64px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexWrap: "wrap" as "wrap",
        }

        if (breakpoints.md) {
            return {
                ...common,
            };
        } else if (breakpoints.sm) {
            return {
                ...common,
            };
        } else if (breakpoints.xs) {
            return {
                ...common,
                paddingTop: "10px"
            };
        } else {
            return {
                ...common,
            };
        }
    })();

    return {
        HeaderComponent
    };
}

export default Styles;
