import React from 'react';
import {Card, Space,} from "antd";
import {SimpleEntity} from "../types/types";
import Meta from "antd/es/card/Meta";
import { noImageFound } from '../utils/helpers';
import {DTwentyIcon} from "../utils/customIcons";

// Define the props based on what you expect to receive
interface PlayerResultProps {
    playerData: {
        playerNumber: number;
        UmCharacter?: SimpleEntity;
        dtHero?: SimpleEntity;
        position: number;
        color: string;
    };
}

const UnmatchedPlayerResult: React.FC<PlayerResultProps> = ({ playerData }) => {
    const API_URL = process.env.REACT_APP_MEDIA_URL;
    return (
        <>
            {playerData.UmCharacter && (
                <Card
                      cover={<img
                          src={playerData.UmCharacter.attributes.image.data?.attributes.url ? `${API_URL}${playerData.UmCharacter.attributes.image.data?.attributes.url}` : noImageFound}
                          alt={playerData.UmCharacter.attributes.image.data?.attributes.alternativeText || playerData.UmCharacter.attributes.Name}
                      />}>
                    <Meta
                        title={playerData.UmCharacter.attributes.Name}
                        description={(
                            <Space wrap>
                                <span>Player {playerData.playerNumber} / </span>
                                <span>
                                    Position on map: {playerData.position}
                                </span>
                                {playerData.color && (
                                    <>
                                        , <DTwentyIcon style={{color: playerData.color, fontSize: '30px'}} />
                                    </>
                                )}
                            </Space>
                        )}
                    />
                </Card>
            )}

            {playerData.dtHero && (
                <Card styles={{body: { padding: 16 }}}
                      cover={<img
                          src={playerData.dtHero.attributes.image.data?.attributes.url ? `${API_URL}${playerData.dtHero.attributes.image.data?.attributes.url}` : noImageFound}
                          alt={playerData.dtHero.attributes.image.data?.attributes.alternativeText || playerData.dtHero.attributes.Name}
                      />}>
                    <Meta
                        title={playerData.dtHero.attributes.Name}
                        description={(
                            <Space wrap>
                                <span>Player {playerData.playerNumber},</span>
                                <span>
                                    Complexity: <img
                                    src={`/img/dice-complexity/${playerData.dtHero.attributes.Complexity}.webp`}
                                    alt={playerData.dtHero.attributes.Complexity} width={23} height={23}
                                    style={{verticalAlign: 'middle'}} />
                                </span>
                                {playerData.color && (
                                    <>
                                        , <DTwentyIcon style={{color: playerData.color, fontSize: '30px'}} />
                                    </>
                                )}
                            </Space>
                        )}
                    />
                </Card>
            )}
        </>
    );
};

export default UnmatchedPlayerResult;
