import {CommonGameParams, GameResult, SimpleEntity} from "../../types/types";
import {filterUniquePairs, getGameFilter, shuffleArray} from "../../utils/helpers";
import {fetchData} from "../api";
import {dynamicParams} from "../params";

interface PlayerSetup {
    playerNumber :number;
    empire :SimpleEntity[];
    color :string | null;
    credits :number;
}
export const newFrontiers = async (params :CommonGameParams): Promise<GameResult> => {

    // Id's of game and expansions
    const gameFilter :number[] = getGameFilter(parseInt(params.gameId), params.expansions)

    const rawEmpires :SimpleEntity[] = await fetchData('new-frontiers-empire-mats', dynamicParams(['Name', 'PairId'], gameFilter, ['image']));

    const shuffledEmpire :SimpleEntity[] = params["exclude_new-frontiers-empire-mats"] ? shuffleArray(rawEmpires.filter(item  => !params["exclude_new-frontiers-empire-mats"]?.includes(item.id) )) : shuffleArray(rawEmpires);
    const filterUnicEmpire :SimpleEntity[] = filterUniquePairs(shuffledEmpire);

    const rawLargeDev :SimpleEntity[] = await fetchData('new-frontiers-large-devs', dynamicParams(['Name', 'PairId', 'IsStartFaction'], gameFilter, ['image']));
    const filterLargeDev :SimpleEntity[] = filterUniquePairs(shuffleArray(rawLargeDev)).sort((a,b) => a.attributes.PairId - b.attributes.PairId);

    const rawSmallDev :SimpleEntity[] = await fetchData('new-frontiers-small-devs', dynamicParams(['Name', 'PairId', 'Cost', 'IsStartFaction'], gameFilter, ['image']));
    const filterSmallDev :SimpleEntity[] = filterUniquePairs(shuffleArray(rawSmallDev));
    filterSmallDev.sort((a,b) => a.attributes.Cost - b.attributes.Cost)


    const shuffledColors :string[] | null = params.colors ? shuffleArray(params.colors) : null
    // Initialize arrays to store final selections
    const playersSetup :PlayerSetup[] =
        Array.from({ length: params.players }, (setup, index) =>
            ({
                playerNumber: index + 1,
                empire: filterUnicEmpire[index] ? [filterUnicEmpire[index]] : [],
                color: shuffledColors ? shuffledColors[index] : null,
                credits: ((params.players >= 4 && index >=3) || (index+1) === params.players) ? 4 : 3,
            }));
    let smallDdevCount :number;
    switch (params.players) {
        case 5:
            smallDdevCount = 3;
            break;
        case 3:
        case 4:
            smallDdevCount = 2;
            break;
        default:
            smallDdevCount = 1;
            break;
    }

    return {
        gameId: params.gameId,
        playersSetup: playersSetup,
        commonSetup: {
            smallDev: filterSmallDev,
            largeDev: filterLargeDev,
            smallDdevCount: smallDdevCount,
        }
    };

}
