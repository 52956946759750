import {Avatar, Checkbox, Collapse, CollapseProps, Divider, Form, List, Space} from "antd";
import {InfoCircleOutlined} from "@ant-design/icons";
import React from "react";
import {GameEntities, SimpleEntity} from "../../types/types";
import {noImageFound} from "../../utils/helpers";

interface ExcludedItemsProps {
    config :any;
    randomizedEntities :{ [key: string]: GameEntities };
    selectedEntities :{ [key: string]: number[] };
    handleSelectAllChange :(itemType: any, checked: boolean) => void;
    checkAllStates :{ [key: string]: boolean };
    indeterminateStates : { [key: string]: boolean };
    handleItemChange :(itemType: any, checkedValues: number[]) => void;
    activeCollapseKeys :string[];
    handleCollapseChange :(keys: string | string[]) => void;
}

const ExcludedItems :React.FC<ExcludedItemsProps> = ({ config, randomizedEntities, selectedEntities, handleSelectAllChange, checkAllStates, indeterminateStates, handleItemChange, activeCollapseKeys, handleCollapseChange }) => {
    const API_URL = process.env.REACT_APP_MEDIA_URL;
    // Element for excluded items
    const collapseItems :CollapseProps['items'] = config && config.gameEntities && config.gameEntities.length > 0 && config.gameEntities.map((itemType: any, index: number) => {
        return {
            key: `exclude-${itemType.apiData}`,
            label: <strong>{itemType.title}</strong>,
            children: <Form.Item
                name={`exclude_${itemType.apiData}`}
                label={(
                    <Checkbox
                        indeterminate={indeterminateStates[itemType.apiData]}
                        onChange={(e) => handleSelectAllChange(itemType, e.target.checked)}
                        checked={checkAllStates[`exclude_${itemType.apiData}`]}
                        style={{ marginLeft: 10 }}
                    >
                        Select All
                    </Checkbox>
                )}
            >
                <Checkbox.Group
                    style={{width: '100%'}}
                    value={selectedEntities[`exclude_${itemType.apiData}`]}
                    onChange={(checkedValues) => handleItemChange(itemType, checkedValues)}
                >
                    {randomizedEntities[itemType.apiData]?.items.length > 0 && randomizedEntities[itemType.apiData]?.items.map((item, index) => (
                        <div key={`exclude_${itemType.apiData}-${index}`} style={{width: '100%'}}>
                            <h4 style={{width: '100%'}}>{item.game_name}</h4>
                            <List style={{width: '100%', maxWidth: 1200}}
                                  grid={{
                                      gutter: 16,
                                      xs: 1,
                                      sm: 2,
                                      md: 3,
                                      lg: 4,
                                      xl: 5,
                                      xxl: 5,
                                  }}
                                  dataSource={item.items}
                                  renderItem={(enItem : SimpleEntity) => (
                                      <List.Item key={`exclude-item-${enItem.id}`}>
                                          <Checkbox  key={`exclude-item-${enItem.id}`} value={enItem.id}>
                                              <Space>
                                                  <Avatar src={enItem.attributes.image.data?.attributes.url ? `${API_URL}${enItem.attributes.image.data?.attributes.url}` : noImageFound}
                                                          size={40}
                                                          shape="square"
                                                  />
                                                  {enItem.attributes.Name}
                                              </Space>
                                          </Checkbox>
                                      </List.Item>
                                  )}
                            />
                        </div>
                    ))}
                </Checkbox.Group>
            </Form.Item>,
            forceRender: true,
        }
    })

    if(collapseItems) {
        return (
            <>
                <Divider orientation="left" orientationMargin="0"><InfoCircleOutlined /> Exclude from Randomization
                </Divider>
                <Collapse activeKey={activeCollapseKeys}
                          onChange={handleCollapseChange} items={collapseItems} size="small" ghost />
                <br />
            </>
        )
    } else {
        return (<></>)
    }
}

export default ExcludedItems;
