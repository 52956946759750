import {CommonGameParams, GameResult, SimpleEntity} from "../../types/types";
import {getGameFilter, shuffleArray} from "../../utils/helpers";
import {fetchData} from "../api";
import {dynamicParams} from "../params";

interface PlayerSetup {
    playerNumber :number;
    AnMaps :SimpleEntity[];
    AnActions :SimpleEntity[];
    color :string | null;
}
export const arknova = async (params :CommonGameParams): Promise<GameResult> => {

    // Id's of game and expansions
    const gameFilter :number[] = getGameFilter(parseInt(params.gameId), params.expansions)

    const rawMaps :SimpleEntity[] = await fetchData('ark-nova-maps', dynamicParams(['Name'], gameFilter, ['image', 'game']))

    const shuffledMaps :SimpleEntity[] = params["exclude_ark-nova-maps"] ? shuffleArray(rawMaps.filter(item  => !params["exclude_ark-nova-maps"]?.includes(item.id) )) :  shuffleArray(rawMaps)

    const commonSetup :any = {}

    const shuffledColors :string[] | null = params.colors ? shuffleArray(params.colors) : null

    let playersSetup :PlayerSetup[] =
        Array.from({ length: params.players }, (el, index) =>
            ({
                playerNumber: index + 1,
                AnMaps: [],
                AnActions: [],
                color: shuffledColors ? shuffledColors[index] : null
            }))

    if(params.alternate_draft_method) {
        commonSetup.AnMaps = shuffledMaps.slice(0, params.players + 1)
    } else {
        shuffledMaps.forEach((map :SimpleEntity, index :number) => {
            const playerIndex = index % params.players
            if (playersSetup[playerIndex].AnMaps.length < 2) {
                playersSetup[playerIndex].AnMaps.push(map)
            }
        });
    }

    const rawActions :SimpleEntity[] = await fetchData('ark-nova-action-cards', dynamicParams(['Name', 'Type'], gameFilter, ['image', 'game']))
    const excludeActions = params["exclude_ark-nova-action-cards"] ? rawActions.filter(item  => !params["exclude_ark-nova-action-cards"]?.includes(item.id) ) : rawActions

    const baseAction :SimpleEntity[] = excludeActions.filter(item  => item.attributes.game?.data.id === 7)
    const expAction :SimpleEntity[] = excludeActions.filter(item  => item.attributes.game?.data.id !== 7)

    if (expAction.length === 0) {
        playersSetup = playersSetup.map((setup, index) => {
            let shuffledActions: SimpleEntity[] = shuffleArray([...baseAction])

            const animalCardIndex = shuffledActions.findIndex(action => action.attributes.Type === 'animals')

            if (animalCardIndex !== -1) {
                const [animalCard] = shuffledActions.splice(animalCardIndex, 1)
                shuffledActions.unshift(animalCard)
            }

            return {
                ...setup,
                AnActions: shuffledActions
            };
        })
    } else {

        let shuffledExpActions = shuffleArray([...expAction])

        playersSetup = playersSetup.map((setup, index) => {
            let shuffledBaseActions = shuffleArray([...baseAction])

            let playerCards = []
            playerCards = shuffleArray(shuffledBaseActions)

            // Replace 2 base cards with corresponding expansion cards
            let replacedCount = 0;
            for (let i = 0; i < playerCards.length && replacedCount < 2; i++) {
                const type = playerCards[i].attributes.Type;
                const expCardIndex = shuffledExpActions.findIndex(action => action.attributes.Type === type);
                if (expCardIndex !== -1) {
                    playerCards[i] = shuffledExpActions.splice(expCardIndex, 1)[0];
                    replacedCount++;
                }
            }

            playerCards = shuffleArray(playerCards)

            // Ensure the animal card is always first
            const animalCardIndex = playerCards.findIndex(action => action.attributes.Type === 'animals');
            if (animalCardIndex !== -1) {
                const [animalCard] = playerCards.splice(animalCardIndex, 1);
                playerCards.unshift(animalCard)
            }

            return {
                ...setup,
                AnActions: playerCards
            };
        });
    }


    return {
        gameId: params.gameId,
        playersSetup: playersSetup,
        commonSetup: commonSetup
    };

}
