import {CommonGameParams, GameResult, SimpleEntity} from "../../types/types";
import {getGameFilter, shuffleArray} from "../../utils/helpers";
import {fetchData} from "../api";
import {dynamicParams} from "../params";

interface PlayerSetup {
    playerNumber :number;
    comLeader :SimpleEntity[];
    color :string | null;
}
export const championsMidgard = async (params :CommonGameParams): Promise<GameResult> => {

    // Id's of game and expansions
    const gameFilter :number[] = getGameFilter(parseInt(params.gameId), params.expansions)

    const rawLeader :SimpleEntity[] = await fetchData('champions-of-midgard-leaders', dynamicParams(['Name'], gameFilter, ['image']));
    const rawMarket :SimpleEntity[] = await fetchData('champions-of-midgard-market-stalls', dynamicParams(['Name', 'Type'], gameFilter, ['image']));

    const shuffledLeaders :SimpleEntity[] = params["exclude_champions-of-midgard-leaders"] ? shuffleArray(rawLeader.filter(item  => !params["exclude_champions-of-midgard-leaders"]?.includes(item.id) )) : shuffleArray(rawLeader);

    const shuffledColors :string[] | null = params.colors ? shuffleArray(params.colors) : null

    const shuffledMarket :SimpleEntity[] = params["exclude_champions-of-midgard-market-stalls"] ? shuffleArray(rawMarket.filter(item  => !params["exclude_champions-of-midgard-market-stalls"]?.includes(item.id) )) : shuffleArray(rawMarket);
    // Separate into economic and military stalls
    const economicStalls = shuffledMarket.filter(item => item.attributes.Type === 'economic');
    const militaryStalls = shuffledMarket.filter(item => item.attributes.Type === 'military');

    let selectedEconomicStalls: SimpleEntity[] = [];
    let selectedMilitaryStalls: SimpleEntity[] = [];

    // Select the appropriate number of stalls based on the number of players
    switch (params.players) {
        case 2:
            selectedEconomicStalls = economicStalls.slice(0, 1);
            selectedMilitaryStalls = militaryStalls.slice(0, 1);
            break;
        case 3:
            selectedEconomicStalls = economicStalls.slice(0, 2);
            selectedMilitaryStalls = militaryStalls.slice(0, 1);
            break;
        case 4:
            selectedEconomicStalls = economicStalls.slice(0, 2);
            selectedMilitaryStalls = militaryStalls.slice(0, 2);
            break;
        case 5:
            selectedEconomicStalls = economicStalls.slice(0, 3);
            selectedMilitaryStalls = militaryStalls.slice(0, 3);
            break;
        default:
            throw new Error(`Unsupported number of players: ${params.players}`);
    }

    const marketStalls = shuffleArray([...selectedEconomicStalls, ...selectedMilitaryStalls]);

    // Initialize arrays to store final selections
    const playersSetup :PlayerSetup[] =
        Array.from({ length: params.players }, (setup, index) =>
            ({
                playerNumber: index + 1,
                comLeader: shuffledLeaders[index] ? [shuffledLeaders[index]] : [],
                color: shuffledColors ? shuffledColors[index] : null,
            }));

    return {
        gameId: params.gameId,
        playersSetup: playersSetup,
        commonSetup: {
            marketStalls: marketStalls ? marketStalls : null
        }
    };

}
