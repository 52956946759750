import {Avatar, Checkbox, CheckboxProps, Divider, Form, List, Space} from "antd";
import {DeploymentUnitOutlined} from "@ant-design/icons";
import React from "react";
import { Game } from "../../types/types";
type CheckboxValueType = number;
interface ExpansionsProps {
    game :Game | null;
    selectedExpansions :number[];
    checkAll: boolean;
    handleExpansionsCheck :(checkedValues: CheckboxValueType[]) => void;
    onCheckAllExp :CheckboxProps['onChange'];
    config :any;
}

const Expansions :React.FC<ExpansionsProps> = ({ game, selectedExpansions, checkAll, onCheckAllExp, handleExpansionsCheck, config }) => {
    const API_URL = process.env.REACT_APP_MEDIA_URL;

    let expansionsData = game?.attributes.expansions.data || [];

    if (config.expSort) {
        const sortField = config.expSort;
        const manualSortOrder = config.expManualSort || [];

        expansionsData = expansionsData.sort((a: any, b: any) => {
            const aValue = a.attributes[sortField];
            const bValue = b.attributes[sortField];

            // Check for manual sort order first
            const aIndex = manualSortOrder.indexOf(aValue);
            const bIndex = manualSortOrder.indexOf(bValue);

            if (aIndex !== -1 && bIndex !== -1) {
                return aIndex - bIndex;
            } else if (aIndex !== -1) {
                return -1;
            } else if (bIndex !== -1) {
                return 1;
            }

            // If not in manual sort order, use default sorting
            if (aValue < bValue) return -1;
            if (aValue > bValue) return 1;
            return 0;
        });
    }

    return (
        <>
            {expansionsData.length > 0 &&
              <>
                <Divider orientation="left" orientationMargin="0"><DeploymentUnitOutlined /> Expansions</Divider>
                <Form.Item label={(
                    <Checkbox indeterminate={selectedExpansions.length > 0 && selectedExpansions.length < expansionsData.length} onChange={onCheckAllExp} checked={checkAll}>
                        Select All Expansions
                    </Checkbox>
                )} name="expansions">
                  <Checkbox.Group style={{width: '100%'}}
                                 value={selectedExpansions}
                                 onChange={handleExpansionsCheck}
                  >
                    <List style={{width: '100%'}}
                          grid={{
                              gutter: 16,
                              xs: 1,
                              sm: 2,
                              md: 2,
                              lg: 3,
                              xl: 4,
                              xxl: 4,
                          }}
                          dataSource={expansionsData}
                          renderItem={(exp) => (
                              <List.Item key={`expansion-${exp.id}`}>
                                  <Checkbox value={exp.id}>
                                      <Space>
                                          <Avatar src={`${API_URL}${exp.attributes.Cover.data.attributes.url}`}
                                                  size={100} />
                                          {exp.attributes.game_name.replace('Unmatched:','')}
                                      </Space>
                                  </Checkbox>
                              </List.Item>
                          )}
                    />

                  </Checkbox.Group>
                </Form.Item>
              </>
            }
        </>
    )
}

export default Expansions;
