import {GameResult, SimpleEntity} from "../types/types";
import React from "react";
import {Card, Flex, Image} from "antd";
import {noImageFound} from "../utils/helpers";
import styles from '../styles/CalicoResults.module.css';
import classNames from "classnames";
import {UserOutlined} from "@ant-design/icons";
import {DTwentyIcon} from "../utils/customIcons";

interface CalicoResultsProps {
    gameData: GameResult;
    config: any;
    noImageGenerate: boolean;
}
const CalicoResults: React.FC<CalicoResultsProps> = ({gameData, config}) => {
    const { playersSetup, commonSetup } = gameData;
    const API_URL = process.env.REACT_APP_MEDIA_URL;
    return (
        <>
            {commonSetup && commonSetup.combinedCats && commonSetup.combinedCats.length > 0 && (
                <Flex wrap gap="large">
                    {commonSetup.combinedCats.map((item :SimpleEntity[]) => (
                        <Card title={item[0].attributes.Name} className={styles.calicoCatCard} key={`cats-${item[0].id}`}>
                            <img
                                src={item[0].attributes.image.data ? `${API_URL}${item[0].attributes.image.data?.attributes.url}` : noImageFound}
                                style={{maxWidth: 300}}
                                alt={item[0].attributes.Name}
                                width='291'
                                height='179'
                            />
                            <img
                                src={item[1].attributes.image.data ? `${API_URL}${item[1].attributes.image.data?.attributes.url}` : noImageFound}
                                className={classNames(styles.calicoPath, styles.calicoPathLeft)}
                                alt={item[1].attributes.Name}

                            />
                            <img
                                src={item[2].attributes.image.data ? `${API_URL}${item[2].attributes.image.data?.attributes.url}` : noImageFound}
                                className={classNames(styles.calicoPath, styles.calicoPathRight)}
                                alt={item[2].attributes.Name}
                            />
                        </Card>
                    ))}
                </Flex>
            )}
            <br/>
            {playersSetup && (
                <>
                {playersSetup.map((playerData) => (
                    <div key={`player-${playerData.playerNumber}`}>
                        <Card
                            title={(
                                <>
                                    <UserOutlined /> Player {playerData.playerNumber}
                                    {playerData.color && (<>, Color: <DTwentyIcon
                                        style={{color: `${playerData.color}`, fontSize: '20px'}} /></>)}
                                </>
                            )}>
                            {playerData.designGoals && (
                                <Flex wrap gap="small">
                                    {playerData.designGoals.map((el: SimpleEntity) => (
                                        <img
                                            key={`designGoals-${el.id}-${playerData.playerNumber}`}
                                            src={el.attributes.image.data ? `${API_URL}${el.attributes.image.data?.attributes.url}` : noImageFound}
                                            alt={el.attributes.Name}
                                            className={styles.calicoDesignGoal}
                                        />
                                    ))}
                                </Flex>
                            )}
                        </Card>
                        <br/>
                    </div>
                ))}
                </>
            )}
        </>
    )
}

export default CalicoResults
