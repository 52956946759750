import {CommonGameParams, GameResult, RemovedGood} from "../../types/types"
import {getGameFilter, shuffleArray} from "../../utils/helpers"

interface PlayerSetup {
    playerNumber :number
    color :string | null
}
// Function to randomly remove goods and store the removed ones
function removeAndCountGoods(pool :string[], countToRemove :number) {
    const removedGoods :any[] = []
    const remainingPool = [...pool] // Clone the pool to avoid modifying the original

    for (let i = 0; i < countToRemove; i++) {
        const randomIndex = Math.floor(Math.random() * remainingPool.length)
        const selectedGood = remainingPool[randomIndex]

        // Remove the selected good from the pool
        remainingPool.splice(randomIndex, 1)

        // Check if it's already in the removedGoods array
        const existingGood = removedGoods.find(good => good.name === selectedGood)
        if (existingGood) {
            existingGood.count += 1
        } else {
            removedGoods.push({ name: selectedGood, count: 1 }) // Add new good
        }
    }

    return { removedGoods, remainingPool }
}
export const orleans = async (params :CommonGameParams): Promise<GameResult> => {
    const shuffledColors :string[] | null = params.colors ? shuffleArray(params.colors) : null
    // Initialize arrays to store final selections
    const playersSetup :PlayerSetup[] =
        Array.from({ length: params.players }, (setup, index) =>
            ({
                playerNumber: index + 1,
                color: shuffledColors ? shuffledColors[index] : null,
            }))

    // Create an array representing the pool of goods
    const goodsPool :string[] = [
        ...Array(24).fill('grain'),
        ...Array(21).fill('cheese'),
        ...Array(18).fill('wine'),
        ...Array(15).fill('wool'),
        ...Array(12).fill('brocade')
    ]

    const shuffledGoods = shuffleArray(goodsPool)
    let removedGoods :RemovedGood[] = []
    let remainingPool :string[] = []
    let selectedGoods :string[]

    switch (params.players) {
        case 2:
            const result = removeAndCountGoods(shuffledGoods, 12)
            removedGoods = result.removedGoods
            remainingPool = result.remainingPool

            selectedGoods = remainingPool.slice(0, 43)
            break
        case 3:
            const result3 = removeAndCountGoods(shuffledGoods, 6)
            removedGoods = result3.removedGoods
            remainingPool = result3.remainingPool

            selectedGoods = remainingPool.slice(0, 48)
            break
        default:
            selectedGoods = shuffledGoods.slice(0, 52)
            break
    }

    return {
        gameId: params.gameId,
        playersSetup: playersSetup,
        commonSetup: {
            removedGoods: removedGoods,
            selectedGoods: selectedGoods,
        }
    }
}
