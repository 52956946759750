import {CommonGameParams, GameResult, SimpleEntity} from "../../types/types";
import {getGameFilter, shuffleArray} from "../../utils/helpers";
import {fetchData} from "../api";
import {dynamicParams} from "../params";

interface PlayerSetup {
    playerNumber :number;
    mech :SimpleEntity[];
    character :SimpleEntity[];
    color :string | null;
}
export const expeditions = async (params :CommonGameParams): Promise<GameResult> => {

    // Id's of game and expansions
    const gameFilter :number[] = getGameFilter(parseInt(params.gameId), params.expansions)

    const rawMechs :SimpleEntity[] = await fetchData('expeditions-mech-mats', dynamicParams(['Name'], gameFilter, ['image']));
    const rawCharacters :SimpleEntity[] = await fetchData('expeditions-characters', dynamicParams(['Name'], gameFilter, ['image']));

    const shuffledMechs :SimpleEntity[] = params["exclude_expeditions-mech-mats"] ? shuffleArray(rawMechs.filter(item  => !params["exclude_expeditions-mech-mats"]?.includes(item.id) )) : shuffleArray(rawMechs);
    const shuffledCharacters :SimpleEntity[] = params["exclude_expeditions-characters"] ? shuffleArray(rawCharacters.filter(item  => !params["exclude_expeditions-characters"]?.includes(item.id) )) : shuffleArray(rawCharacters);
    const shuffledColors :string[] | null = params.colors ? shuffleArray(params.colors) : null
    // Initialize arrays to store final selections
    const playersSetup :PlayerSetup[] =
        Array.from({ length: params.players }, (setup, index) =>
            ({
                playerNumber: index + 1,
                mech: shuffledMechs[index] ? [shuffledMechs[index]] : [],
                character: shuffledCharacters[index] ? [shuffledCharacters[index]] : [],
                color: shuffledColors ? shuffledColors[index] : null,
            }));

    return {
        gameId: params.gameId,
        playersSetup: playersSetup,
        commonSetup: {}
    };

}
