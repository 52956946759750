import React from 'react';
import {Card} from "antd";
import {SimpleEntity} from "../types/types";
import Meta from "antd/es/card/Meta";
import {noImageFound} from "../utils/helpers";

// Define the props based on what you expect to receive
interface CommonResultProps {
    commonData: {
        UmMap?: SimpleEntity;
        // Add other properties based on different games and their results
    };
}

const UnmatchedCommonResult: React.FC<CommonResultProps> = ({ commonData }) => {
    const API_URL = process.env.REACT_APP_MEDIA_URL;
    return (
        <>
        {commonData.UmMap ?  (
            <Card
                  cover={<img
                      src={commonData.UmMap.attributes.image.data?.attributes.url ? `${API_URL}${commonData.UmMap.attributes.image.data?.attributes.url}` : noImageFound}
                      alt={commonData.UmMap.attributes.image.data?.attributes.alternativeText || commonData.UmMap.attributes.Name}
                  />}>
                <Meta
                    title={commonData.UmMap.attributes.Name}
                    description={`From ${commonData.UmMap.attributes.game?.data.attributes.game_name}`}
                />
            </Card>
        ) : (
            <Card title="Common elements" bordered={false}>

            </Card>
        )}
        </>
    );
};

export default UnmatchedCommonResult;
