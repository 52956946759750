// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --bp-md: 768px;
  --bp-lg: 1024px;
  --bp-xl: 1200px;
}
html, body {
  height: 100%;
}
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*min-height: 100%;*/
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  min-height: 100vh;
}

.site-logo {
  margin: 0;
  font-size: 16px;
  line-height: normal;
}
.site-logo a {
  display: inline-flex;
  align-items: center;
  color: currentColor;
}
.site-logo img {
  position: relative;
  top: 2px;
}

.blurred {
  filter: blur(30px);
  transition: filter 0.5s ease; /* For smooth transition */
}

.clear {
  filter: none;
  transition: filter 0.5s ease;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,eAAe;EACf,eAAe;AACjB;AACA;EACE,YAAY;AACd;AACA;EACE,SAAS;EACT,UAAU;EACV;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;EAClC,oBAAoB;AACtB;;AAEA;EACE;aACW;AACb;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,SAAS;EACT,eAAe;EACf,mBAAmB;AACrB;AACA;EACE,oBAAoB;EACpB,mBAAmB;EACnB,mBAAmB;AACrB;AACA;EACE,kBAAkB;EAClB,QAAQ;AACV;;AAEA;EACE,kBAAkB;EAClB,4BAA4B,EAAE,0BAA0B;AAC1D;;AAEA;EACE,YAAY;EACZ,4BAA4B;AAC9B","sourcesContent":[":root {\n  --bp-md: 768px;\n  --bp-lg: 1024px;\n  --bp-xl: 1200px;\n}\nhtml, body {\n  height: 100%;\n}\nbody {\n  margin: 0;\n  padding: 0;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  /*min-height: 100%;*/\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n\n#root {\n  min-height: 100vh;\n}\n\n.site-logo {\n  margin: 0;\n  font-size: 16px;\n  line-height: normal;\n}\n.site-logo a {\n  display: inline-flex;\n  align-items: center;\n  color: currentColor;\n}\n.site-logo img {\n  position: relative;\n  top: 2px;\n}\n\n.blurred {\n  filter: blur(30px);\n  transition: filter 0.5s ease; /* For smooth transition */\n}\n\n.clear {\n  filter: none;\n  transition: filter 0.5s ease;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
