import {CommonGameParams, GameResult, SimpleEntity} from "../../types/types";
import {getGameFilter, shuffleArray} from "../../utils/helpers";
import {fetchData} from "../api";
import {dynamicParams} from "../params";

export const rollingRealms = async (params :CommonGameParams): Promise<GameResult> => {

    // Id's of game and expansions
    const gameFilter :number[] = getGameFilter(parseInt(params.gameId), params.expansions)

    const rawRealms :SimpleEntity[] = await fetchData('rolling-realms-realms', dynamicParams(['Name'], gameFilter, ['image']));

    const shuffledRealms :SimpleEntity[] = params["exclude_rolling-realms-realms"] ? shuffleArray(rawRealms.filter(item  => !params["exclude_rolling-realms-realms"]?.includes(item.id) )) : shuffleArray(rawRealms);

    const round1 :SimpleEntity[] | null = shuffledRealms.length >= 3 ? shuffledRealms.splice(0, 3) : null;
    const round2 :SimpleEntity[] | null = shuffledRealms.length >= 3 ? shuffledRealms.splice(0, 3) : null;
    const round3 :SimpleEntity[] | null = shuffledRealms.length >= 3 ? shuffledRealms.splice(0, 3) : null;

    return {
        gameId: params.gameId,
        playersSetup: [],
        commonSetup: {
            round1: round1,
            round2: round2,
            round3: round3,
        }
    };

}
