import React from 'react';
import {Typography, Space, Card, Grid} from 'antd';
import {FacebookFilled, MailFilled } from "@ant-design/icons";
import {isSmallScreen} from "../utils/myBreakpoints";

const { Title, Paragraph } = Typography;
const { Meta } = Card;
const { useBreakpoint } = Grid;
const AboutPage: React.FC = () => {
    document.title = "About - Tabletop Setup Wizard"
    return (
        <div style={{maxWidth: 1200, margin: 'auto'}}>
            <Space direction="vertical" size="large">
                <Title level={1}>About Tabletop Setup Wizard</Title>
                <Space size="large" direction={isSmallScreen(useBreakpoint()) ? 'vertical' : 'horizontal'} >
                    <Card
                        style={{ width: 240 }}
                        cover={<img alt="Oleksii" src="/img/IMG_1352.jpg" />}
                    >
                        <Meta title="Hello, my name is Oleksii"/>
                    </Card>
                    <div>
                        <Paragraph>
                            Welcome to Tabletop Setup Wizard, your ultimate companion for randomizing the setup process of your favorite board games!
                        </Paragraph>
                        <Paragraph>
                            The most exciting part of any board game is the gameplay itself. However, in some games randomization part may be not very inconvenient, specially when you need to shuffle some large mats or non-standard cards. This wizard is here to help you with that task.
                        </Paragraph>
                        <Paragraph>
                            While many games already have randomizer tools available, they are scattered across various websites. My goal is to consolidate these games into one convenient location. Additionally, I aim to provide useful features such as randomizing based on specific difficulty levels (e.g., Civilizations in Tapestry) or excluding certain elements from the randomization process.
                        </Paragraph>
                        <Paragraph>
                            I plan to add other games in future, so if there's a game you'd like to see included, please let me know via <Typography.Link href="mailto:ttsetupwizard@gmail.com"><MailFilled /> mail</Typography.Link> or on <Typography.Link href="https://www.facebook.com/groups/958020602688119"><FacebookFilled />Facebook</Typography.Link>
                        </Paragraph>
                        <Paragraph>
                            If you have any questions, feedback, or suggestions, I’d love to hear from you. Feel free to reach out to me at contacts above
                        </Paragraph>
                        <Paragraph>
                            Thank you for choosing Tabletop Setup Wizard. Let’s make every game night unforgettable!
                        </Paragraph>
                    </div>

                </Space>


            </Space>
        </div>

    );
};

export default AboutPage;
