import {CommonGameParams, GameResult, SimpleEntity} from "../../types/types";
import {getGameFilter, shuffleArray} from "../../utils/helpers";
import {fetchData} from "../api";
import {dynamicParams} from "../params";

interface PlayerSetup {
    playerNumber :number;
    hive :SimpleEntity[];
    apiaryFaction :SimpleEntity[];
    color :string | null;
}
export const apiary = async (params :CommonGameParams): Promise<GameResult> => {

    // Id's of game and expansions
    const gameFilter :number[] = getGameFilter(parseInt(params.gameId), params.expansions)

    const rawHives :SimpleEntity[] = await fetchData('apiary-hives', dynamicParams(['Name'], gameFilter, ['image']));
    const rawFactions :SimpleEntity[] = await fetchData('apiary-factions', dynamicParams(['Name', 'isStartFaction'], gameFilter, ['image']));
    const rawCarve :SimpleEntity[] = await fetchData('apiary-carve-tiles', dynamicParams(['Name'], gameFilter, ['image']));
    const rawDance :SimpleEntity[] = await fetchData('apiary-dance-tiles', dynamicParams(['Name'], gameFilter, ['image']));

    const filterExcludedFactions :SimpleEntity[] = rawFactions.filter(item  => !params["exclude_apiary-factions"]?.includes(item.id) )

    const shuffledHives :SimpleEntity[] = params["exclude_apiary-hives"] ? shuffleArray(rawHives.filter(item  => !params["exclude_apiary-hives"]?.includes(item.id) )) : shuffleArray(rawHives);
    const shuffledFactions :SimpleEntity[] = params.starter ? shuffleArray(filterExcludedFactions.filter(f => f.attributes.isStartFaction)) : shuffleArray(filterExcludedFactions);
    const shuffledCarve :SimpleEntity[] = params["exclude_apiary-carve-tiles"] ? shuffleArray(rawCarve.filter(item  => !params["exclude_apiary-carve-tiles"]?.includes(item.id) )) : shuffleArray(rawCarve);
    const shuffledDance :SimpleEntity[] = params["exclude_apiary-carve-tiles"] ? shuffleArray(rawDance.filter(item  => !params["exclude_apiary-dance-tiles"]?.includes(item.id) )) : shuffleArray(rawDance);
    const shuffledColors :string[] | null = params.colors ? shuffleArray(params.colors) : null
    // Initialize arrays to store final selections
    let playersSetup :PlayerSetup[] =
        Array.from({ length: params.players }, (setup, index) =>
            ({
                playerNumber: index + 1,
                hive: shuffledHives[index] ? [shuffledHives[index]] : [],
                apiaryFaction: [],
                color: shuffledColors ? shuffledColors[index] : null
            }));

    if(params.starter) {
        playersSetup = playersSetup.map((setup, index) => {
            return {
                ...setup,
                apiaryFaction: shuffledFactions[index] ? [
                    shuffledFactions[index]
                ] : [],
            };
        })
    } else {
        shuffledFactions.forEach((faction :SimpleEntity, index :number) => {
            const playerIndex = index % params.players;
            if (playersSetup[playerIndex].apiaryFaction.length < 2) {
                playersSetup[playerIndex].apiaryFaction.push(faction);
            }
        });
    }

    const carveQuantity = params.players <=3 ? 6 : 9;
    const danceQuantity = params.players <=3 ? 2 : 3;

    return {
        gameId: params.gameId,
        playersSetup: playersSetup,
        commonSetup: {
            carveTiles: shuffledCarve.slice(0, carveQuantity),
            danceTiles: shuffledDance.slice(0, danceQuantity)
        }
    };

}
