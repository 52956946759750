// src/services/api.ts
import axios from 'axios';
import qs from 'qs';

const API_URL = process.env.REACT_APP_API_URL;
const TOKEN_API = process.env.REACT_APP_TOKEN_API;


export const fetchGames = async (params?: Record<string, any>): Promise<any[]> => {
  params = params ? params : {
    sort: ['game_name:asc'],
    filters: {
      game_type: {
        $eq: 'Base Game',
      },
    },
    populate: ['Cover', 'publishers']
  };
  const queryString = qs.stringify(params, { addQueryPrefix: true });
  if(process.env.NODE_ENV === 'development') {
    // console.log("queryString: ", queryString)
  }
  try {
    const response = await axios.get(`${API_URL}/games${queryString}`, {
      headers: {
        Authorization: `Bearer ${TOKEN_API}`,
      },
    });
    return response.data.data;
  } catch (error) {
    console.error("Failed to fetch games:", error);
    throw error;
  }
};

export const fetchGameById = async (gameId: string | undefined): Promise<any> => {
  const queryParams = {
    populate: {
      expansions: {
        publicationState: 'live',
        populate: ['Cover'],
      },
      special_options: {
        populate: ['select_options'],
      },
      image_credit : {
        populate: ['link'],
      }
    },
  };
  const queryString = qs.stringify(queryParams, { addQueryPrefix: true });
  // if(process.env.NODE_ENV === 'development') {
    // console.log("fetchGameById: ", queryString)
  // }
  try {
    const response = await axios.get(`${API_URL}/games/${gameId}${queryString}`, {
      headers: {
        Authorization: `Bearer ${TOKEN_API}`,
      },
    });
    return response.data.data;
  } catch (error) {
    console.error(`Failed to fetch game with ID ${gameId}:`, error);
    throw error;
  }
};

export const fetchGameByName = async (gameName: string | undefined): Promise<any> => {
  const queryParams = {
    populate: {
      expansions: {
        publicationState: 'live',
        populate: ['Cover', 'colors', 'modules'],
      },
      special_options: {
        populate: ['select_options'],
      },
      image_credit : {
        populate: ['link'],
      },
      colors: {
        fields: ['Name', 'Color'],
      },
      modules: {
        fields: ['Name', 'slug'],
      }
    },
    filters: {
      gameSlug: {
        $eq: gameName
      }
    }
  };
  const queryString = qs.stringify(queryParams, { addQueryPrefix: true });
  // if(process.env.NODE_ENV === 'development') {
  // console.log("fetchGameByName: ", queryString)
  // }
  try {
    const response = await axios.get(`${API_URL}/games?${queryString}`, {
      headers: {
        Authorization: `Bearer ${TOKEN_API}`,
      },
    });
    return response.data.data[0];
  } catch (error) {
    console.error(`Failed to fetch game with Name ${gameName}:`, error);
    throw error;
  }
};


export const fetchData = async (path: string, params: Record<string, any>): Promise<any[]> => {
  const queryString = qs.stringify(params, { addQueryPrefix: true });
  // if(process.env.NODE_ENV === 'development') {
  //   console.log("fetchDataQueryString: ", queryString)
  // }
  try {
    const response = await axios.get(`${API_URL}/${path}${queryString}`, {
      headers: {
        Authorization: `Bearer ${TOKEN_API}`,
      },
    });
    return response.data.data;
  } catch (error) {
    console.error(`Failed to fetch Data in ${path}${queryString}:`, error);
    throw error;
  }
}
