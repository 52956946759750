import React, { createContext, useContext, useState, useEffect, ReactNode, useCallback } from 'react';
import { fetchGames } from '../services/api';
import {Game} from "../types/types"; // Make sure this points to your actual API service

interface GamesContextState {
    games: Game[];
    loading: boolean;
    error: Error | null;
    refreshGames: (params?: Record<string, any>) => void;
}

const GamesContext = createContext<GamesContextState | undefined>(undefined);

export const GamesProvider = ({ children }: { children: ReactNode }) => {
    const [games, setGames] = useState<Game[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<Error | null>(null);

    const loadGames = useCallback(async (params?: Record<string, any>) => {
        setLoading(true);
        setError(null);
        try {
            const data = await fetchGames(params);
            // console.log(data);
            setGames(data); // Assuming fetchGames returns an array of games
            setLoading(false);
        } catch (err) {
            setError(err instanceof Error ? err : new Error('Failed to fetch games'));
            setLoading(false);
        }
    }, []);

    // Effect to load games on mount
    useEffect(() => {
        loadGames();
    }, [loadGames]);

    return (
        <GamesContext.Provider value={{ games, loading, error, refreshGames: loadGames }}>
            {children}
        </GamesContext.Provider>
    );
};

export const useGames = () => {
    const context = useContext(GamesContext);
    if (context === undefined) {
        throw new Error('useGames must be used within a GamesProvider');
    }
    return context;
};
