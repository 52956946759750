import React from 'react';
import {useLocation} from 'react-router-dom';
import {Layout, Input, Space} from "antd";
import Styles from '../styles/styles';
const { Header } = Layout;
interface NavBarProps {
    onSearch: (value: string) => void;
}
const NavBar: React.FC<NavBarProps> = ({ onSearch }) => {

    let location = useLocation();
    const S = Styles();
    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        onSearch(e.target.value);
    };

    return (
        <Header style={S.HeaderComponent}>
            <h1 className="site-logo">
                <a href="/">
                    <img src="/img/logo.png" alt="Logo" width="55" height="52" />
                    <span>Tabletop Setup Wizard</span>
                </a>
            </h1>
            <Space>
                {location.pathname === '/' && (
                    <Input
                        placeholder="Search for game"
                        onChange={handleSearchChange}
                        style={{width: 300}}
                    />
                )}
            </Space>
        </Header>
    );
};

export default NavBar;
