import React from 'react';
import '../styles/DiceLoader.css'
import {Typography} from "antd";

interface DiceLoaderProps {
    message?: string;
}

const DiceLoader: React.FC<DiceLoaderProps> = ({ message }) => {
    return (
        <div className="dice-loader-container">
            <svg id="diceLoader" data-name="5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 133.85 155.44">
                <title>dice</title>
                <polygon className="breathe second polygon1"
                         points="66.92 154.13 131.55 116.12 66.92 108.51 66.92 154.13" />
                <polygon className="breathe second polygon2"
                         points="66.92 154.13 66.92 108.51 2.3 116.12 66.92 154.13" />
                <polygon className="breathe third polygon3" points="2.3 116.12 36.51 55.3 2.3 40.09 2.3 116.12" />
                <polygon className="breathe third polygon4" points="36.51 55.3 2.3 40.09 66.92 2.08 36.51 55.3" />
                <polygon className="breathe fourth polygon5" points="97.33 55.3 131.55 40.09 66.92 2.08 97.33 55.3" />
                <polygon className="breathe fourth polygon6"
                         points="97.33 55.3 131.55 116.12 131.55 40.09 97.33 55.3" />
                <polygon className="breathe first polygon7"
                         points="66.92 108.51 131.55 116.12 97.33 55.3 66.92 108.51" />
                <polygon className="breathe first polygon8" points="36.51 55.3 2.3 116.12 66.92 108.51 36.51 55.3" />
                <polygon className="breathe first polygon9" points="36.51 55.3 66.92 2.08 97.33 55.3 36.51 55.3" />
                <polygon className="breathe polygon10" points="66.92 108.51 97.33 55.3 36.51 55.3 66.92 108.51" />
            </svg>
            {message && (
                <Typography.Text>{message}</Typography.Text>
            )}
        </div>
    );
};

export default DiceLoader;
