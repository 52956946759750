import {CommonGameParams, GameResult, SimpleEntity} from "../../types/types";
import {getGameFilter, shuffleArray} from "../../utils/helpers";
import {fetchData} from "../api";
import {dynamicParams} from "../params";

interface PlayerSetup {
    playerNumber: number;
    dtHero: SimpleEntity;
    color :string | null;
}
export const diceThrone = async (params :CommonGameParams): Promise<GameResult> => {

    // Id's of game and expansions
    const gameFilter :number[] = getGameFilter(parseInt(params.gameId), params.expansions)

    const rawHeroes :SimpleEntity[] = await fetchData('dice-throne-heroes', dynamicParams(['Name', 'Complexity'], gameFilter, ['image'], [{Complexity: {
            $in: params.dt_complexity,
        }}]));

    const shuffledHeroes :SimpleEntity[] = params["exclude_dice-throne-heroes"] ? shuffleArray(rawHeroes.filter(item  => !params["exclude_dice-throne-heroes"]?.includes(item.id) )) :  shuffleArray(rawHeroes);

    const shuffledColors :string[] | null = params.colors ? shuffleArray(params.colors) : null

    // Initialize arrays to store final selections
    const playersSetup :PlayerSetup[] =
        Array.from({ length: params.players }, (setup, index) =>
            ({
                playerNumber: index + 1,
                dtHero: shuffledHeroes[index],
                color: shuffledColors ? shuffledColors[index] : null
            }));

    return {
        gameId: params.gameId,
        playersSetup: playersSetup,
        commonSetup: {}
    };

}
