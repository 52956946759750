import {filterUniquePairs, getGameFilter, shuffleArray} from "../../utils/helpers";
import {fetchData} from "../api";
import {CommonGameParams, GameResult, SimpleEntity} from "../../types/types";
import {dynamicParams} from "../params";

interface PlayerSetup {
    faction: SimpleEntity[] | null;
    playerMat: SimpleEntity[] | null;
    mechMod?: SimpleEntity[] | null;
    infraMod?: SimpleEntity[] | null;
}

const assignModTiles = <T extends SimpleEntity>(
    playersSetup: PlayerSetup[],
    rawModTiles: T[],
    numTilesPerPlayer: number,
    modType: 'mechMod' | 'infraMod',
): PlayerSetup[] => {
    // Shuffle the mod tiles
    let shuffledModTiles: T[] = shuffleArray(rawModTiles);

    for (let i = 0; i < playersSetup.length; i++) {
        let playerTiles: T[] = [];
        let attempts = 0;

        while (playerTiles.length < numTilesPerPlayer && attempts < shuffledModTiles.length) {
            const tile = shuffledModTiles[attempts];

            if (!playerTiles.some(t => t.attributes.Name === tile.attributes.Name)) {
                playerTiles.push(tile);
                shuffledModTiles.splice(attempts, 1); // Remove the tile from the pool
            } else {
                attempts++;
            }

            if (attempts >= shuffledModTiles.length && playerTiles.length < numTilesPerPlayer) {
                shuffledModTiles = shuffleArray(rawModTiles);
                playerTiles = [];
                attempts = 0;
            }
        }

        if (playerTiles.length < numTilesPerPlayer) {
            throw new Error(`Not enough unique ${modType} tiles for each player`);
        }

        playersSetup[i][modType] = playerTiles;
    }

    return playersSetup;
};
export const scythe = async (params :CommonGameParams): Promise<GameResult> => {

    // Id's of game and expansions
    const gameFilter :number[] = getGameFilter(parseInt(params.gameId), params.expansions)

    if(params.mp_automa) {
        if(params.expansions.includes(11)) {
            params.players = params.players + Math.floor(Math.random() * ((7 - params.players) - 1 + 1) + 1);
        } else {
            params.players = params.players + Math.floor(Math.random() * ((5 - params.players) - 1 + 1) + 1);
        }
    }

    const rawFactions :SimpleEntity[] = await fetchData('scythe-factions', dynamicParams(['Name', 'isRandomBase'], gameFilter, ['image', 'scythe_player_mat', 'scythe_tesla_mech_mod_tiles']));
    const rawPlayerMats :SimpleEntity[] = await fetchData('scythe-player-mats', dynamicParams(['Name'], gameFilter, ['image']));
    const rawStructureBonuses :SimpleEntity[] = await fetchData('scythe-structure-bonus-tiles', dynamicParams(['Name'], gameFilter, ['image']));
    const rawAirship :SimpleEntity[] = await fetchData('scythe-airship-tiles', dynamicParams(['Name', 'Type'], gameFilter, ['image']));
    const rawResolution :SimpleEntity[] = await fetchData('scythe-resolution-tiles', dynamicParams(['Name'], gameFilter, ['image']));
    const rawMechTiles :SimpleEntity[] = await fetchData('scythe-mech-mod-tiles', dynamicParams(['Name', 'NoAutoma'], gameFilter, ['image']));
    const rawInfraTiles :SimpleEntity[] = await fetchData('scythe-infra-mods-tiles', dynamicParams(['Name', 'NoAutoma'], gameFilter, ['image']));
    const rawTriumphTrack :SimpleEntity[] = await fetchData('scythe-triumph-tracks', dynamicParams(['Name'], gameFilter, ['image']));
    const rawTriumphTiles :SimpleEntity[] = await fetchData('scythe-triumph-tiles', dynamicParams(['Name'], gameFilter, ['image']));

    const shuffledStructureBonuses :SimpleEntity[] = params["exclude_scythe-structure-bonus-tiles"] ? shuffleArray(rawStructureBonuses.filter(item  => !params["exclude_scythe-structure-bonus-tiles"]?.includes(item.id) )) : shuffleArray(rawStructureBonuses);
    const shuffledTriumphTilesBonuses :SimpleEntity[] = params["exclude_scythe-triumph-tiles"] ? shuffleArray(rawTriumphTiles.filter(item  => !params["exclude_scythe-triumph-tiles"]?.includes(item.id) )) : shuffleArray(rawTriumphTiles);
    const shuffledAirship :SimpleEntity[] = params["exclude_scythe-airship-tiles"] ? shuffleArray(rawAirship.filter(item  => !params["exclude_scythe-airship-tiles"]?.includes(item.id) )) : shuffleArray(rawAirship);

    const shuffledResolution :SimpleEntity[]= params["exclude_scythe-resolution-tiles"] ? shuffleArray(rawResolution.filter(item  => !params["exclude_scythe-resolution-tiles"]?.includes(item.id) )) :  shuffleArray(rawResolution);

    const excludedFactions = rawFactions.filter(item  => !params["exclude_scythe-factions"]?.includes(item.id) )
    const excludedPlayerMats = rawPlayerMats.filter(item  => !params["exclude_scythe-player-mats"]?.includes(item.id) )

    let shuffledFactions: SimpleEntity[] = shuffleArray(excludedFactions);
    let shuffledPlayerMats: SimpleEntity[] = shuffleArray(excludedPlayerMats);

    let playersSetup: PlayerSetup[] = Array.from({ length: params.players }, (setup, index) => ({
        faction: shuffledFactions[0] ? shuffledFactions.splice(0, 1) : [],
        playerMat: shuffledPlayerMats[0] ? shuffledPlayerMats.splice(0, 1) : [],
    }));

    //Triumph tracks
    let triumphTrack = null;
    if(params.triumph_track) {
        triumphTrack = shuffledTriumphTilesBonuses ? shuffledTriumphTilesBonuses.slice(0, 10) : null;
    } else {
        if(rawTriumphTrack.length > 0) {
            const  shuffleTriumphTrack = shuffleArray(rawTriumphTrack)[0];

            if(shuffleTriumphTrack.attributes.Name === 'Random') {
                triumphTrack = shuffledTriumphTilesBonuses ? shuffledTriumphTilesBonuses.slice(0, 10) : null;
            } else {
                triumphTrack = [shuffleTriumphTrack]
            }
        }
    }

    // Modular board
    const rawBoard :SimpleEntity[] = await fetchData('scythe-boards', dynamicParams(['Name'], gameFilter, ['image']));
    const rawBoardTiles :SimpleEntity[] = await fetchData('scythe-board-tiles', dynamicParams(['Name', 'pair_id'], gameFilter, ['image']));
    let rawBases :SimpleEntity[] = await fetchData('scythe-bases', dynamicParams(['Name', 'fenris'], gameFilter, ['image']));

    // If fenris exp not selected
    if (!params.expansions.includes(13)) {
        rawBases = rawBases.filter((base) => !base.attributes.fenris);
    } else {
        rawBases = rawBases.filter((base) => base.id !== 9).slice(0, rawBases.length - 2);
    }
    const shuffleBases :SimpleEntity[] = params["exclude_scythe-bases"] ? shuffleArray(rawBases.filter(item  => !params["exclude_scythe-bases"]?.includes(item.id) )) :  shuffleArray(rawBases);

    // board tiles by players count
    const shuffledBoardTiles = shuffleArray(rawBoardTiles);
    const boardTiles = filterUniquePairs(shuffledBoardTiles);

    playersSetup.forEach((setup: PlayerSetup,index) => {
        if (
            (setup.faction && setup.faction?.length > 0 && setup.playerMat && setup.playerMat?.length > 0) &&
            (
                setup.faction[0].attributes.scythe_player_mat?.data &&
                setup.faction[0].attributes.scythe_player_mat?.data?.id === setup.playerMat[0].id)
        ) {
            setup.faction[0].attributes.factionMatAlert = true;
        }


        if (
            (setup.faction && setup.faction?.length > 0) &&
            setup.faction[0].attributes.Name === 'Tesla' && setup.faction[0].attributes.scythe_tesla_mech_mod_tiles)
        {
            const shuffledMechTiles :SimpleEntity[] = shuffleArray(setup.faction[0].attributes.scythe_tesla_mech_mod_tiles.data)
            setup.faction[0].attributes.scythe_tesla_mech_mod_tiles.data = shuffledMechTiles.slice(0, 4);
        }
    })


    // Assign randomBase to factions with isRandomBase true
    const unusedFactions :SimpleEntity[] = shuffledFactions.filter(f => !f.attributes.isRandomBase);
    playersSetup.forEach(setup => {
        const faction = setup.faction;
        if (faction && faction.length > 0 && faction[0].attributes.isRandomBase && unusedFactions.length > 0) {
            const randomIndex = Math.floor(Math.random() * unusedFactions.length);
            faction[0].attributes.randomBase = unusedFactions[randomIndex].attributes.Name;
            unusedFactions.splice(randomIndex, 1); // Remove the assigned faction from the pool
        }
    });

    if(rawMechTiles.length > 0) {
        playersSetup = assignModTiles(playersSetup, rawMechTiles, 4, 'mechMod');
    }

    if(rawInfraTiles.length > 0) {
        playersSetup = assignModTiles(playersSetup, rawInfraTiles, 2, 'infraMod');
    }

    return {
        gameId: params.gameId,
        playersSetup: playersSetup.map((setup, index) => ({
            playerNumber: index + 1,
            faction: setup.faction,
            playerMat: setup.playerMat,
            mechMod: params.mech_mods ? setup.mechMod ?? null : null,
            infraMod: params.infra_mod ? setup.infraMod ?? null : null,
        })),
        commonSetup: {
            structureBonus: shuffledStructureBonuses[0] ? [shuffledStructureBonuses[0]] : [],
            airshipTiles: shuffledAirship.length > 0 ? [
                shuffledAirship?.filter(tile => tile.attributes.Type === 'aggressive (red)')[0],
                shuffledAirship?.filter(tile => tile.attributes.Type === 'passive (green)')[0],
            ] : [],
            resolutionTile: shuffledResolution?.length > 0 ? [shuffledResolution[0]] : [],
            triumphTiles: triumphTrack ? triumphTrack : null,
            modularBoard: shuffleArray(rawBoard)[0] ? [shuffleArray(rawBoard)[0]] : null,
            factionBases: shuffleBases ? shuffleBases : null,
            boardTiles: boardTiles ? boardTiles : null,
            madTesla: (params.mad_tesla && params.expansions.includes(13)) ? [
                {
                    id: 1,
                    attributes: {
                        Name:  Math.round(Math.random()) ? "USE Mad Tesla Mod" : "NOT USE Mad Tesla Mod",
                        image: {
                            data: null
                        }
                    }
                }
            ] : null,
        }
    };

}
