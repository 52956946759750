import {tapestry} from "./tapestry";
import {TapestryGameParams} from "../../types/tapestry";
import {CommonGameParams, GameParams, GameResult} from "../../types/types";
import {scythe} from "./scythe";
import {expeditions} from "./expeditions";
import {apiary} from "./apiary";
import {unmatched} from "./unmatched";
import {arknova} from "./ark-nova";
import {duneImperium} from "./dune-imperium";
import {championsMidgard} from "./championsMidgard";
import {newFrontiers} from "./new-frontiers";
import {rollingRealms} from "./rollingRealms";
import {diceThrone} from "./dice-throne";
import {spiritIsland} from "./spirit-island";
import {teotihuacan} from "./teotihuacan";
import {calico} from "./calico";
import {lrArnak} from "./arnak";
import {orleans} from "./orleans";

// function isTapestryResult(result: any): result is TapestryResult {
//     return result !== null &&
//         typeof result === "object" &&
//         Array.isArray(result.playersSetup) &&
//         'commonSetup' in result &&
//         'gameId' in result;
// }
export const getRandomStaff = async <TParams extends GameParams, TResult extends GameResult>(params: TParams): Promise<TResult> => {
    if (!params) {
        throw new Error("No parameters provided");
    }

    let result: TResult;

    switch (params.gameId) {
        case "1": // Tapestry
            const tapestryResult = await tapestry(params as unknown as TapestryGameParams);
            // if (isTapestryResult(tapestryResult)) {
                result = tapestryResult as unknown as TResult;
            // } else {
            //     throw new Error("Invalid result type from tapestry function");
            // }
            break;
        case "5": // Scythe
            const scytheResult = await scythe(params as unknown as CommonGameParams);
            result = scytheResult as unknown as TResult;
            break;
        case "6": // Apiary
            const apiaryResult = await apiary(params as unknown as CommonGameParams);
            result = apiaryResult as unknown as TResult;
            break;
        case "7": // Ark Nova
            const arkNovaResult = await arknova(params as unknown as CommonGameParams);
            result = arkNovaResult as unknown as TResult;
            break;
        case "8": // Expeditions
            const expeditionsResult = await expeditions(params as unknown as CommonGameParams);
            result = expeditionsResult as unknown as TResult;
            break;
        case "9": // Unmatched
            const unmatchedResult = await unmatched(params as unknown as CommonGameParams);
            result = unmatchedResult as unknown as TResult;
            break;
        case "14": // Rolling Realms
            const RRResult = await rollingRealms(params as unknown as CommonGameParams);
            result = RRResult as unknown as TResult;
            break;
        case "34": // Dune Imperium
            const duneResult = await duneImperium(params as unknown as CommonGameParams);
            result = duneResult as unknown as TResult;
            break;
        case "40": // New Frontiers
            const newFrontiersResult = await newFrontiers(params as unknown as CommonGameParams);
            result = newFrontiersResult as unknown as TResult;
            break;
        case "42": // Champions of Midgard
            const comResult = await championsMidgard(params as unknown as CommonGameParams);
            result = comResult as unknown as TResult;
            break;
        case "86": // Dice throne
            const dtResult = await diceThrone(params as unknown as CommonGameParams);
            result = dtResult as unknown as TResult;
            break;
        case "98": // Spirit Island
            const siResult = await spiritIsland(params as unknown as CommonGameParams);
            result = siResult as unknown as TResult;
            break;
        case "106": // Teotihuacan
            const teoResult = await teotihuacan(params as unknown as CommonGameParams);
            result = teoResult as unknown as TResult;
            break;
        case "112": // Teotihuacan
            const calicoResult = await calico(params as unknown as CommonGameParams);
            result = calicoResult as unknown as TResult;
            break;
        case "115": // Arnak
            const arnakResult = await lrArnak(params as unknown as CommonGameParams);
            result = arnakResult as unknown as TResult;
            break;
        case "119": // Orleans
            const orleansResult = await orleans(params as unknown as CommonGameParams);
            result = orleansResult as unknown as TResult;
            break;
        default:
            throw new Error("Game not supported");
    }

    return result;
};
