import {Card, Checkbox, Form, Popover, Radio, Segmented, Slider, Space, Switch,} from "antd";
import React from "react";
import {Game} from "../../types/types";
import {CheckOutlined, CloseOutlined, InfoCircleOutlined, QuestionOutlined} from "@ant-design/icons";

interface SpiritIslandOptionsProps {
    game :Game | null;
}

const SpiritIslandOptions :React.FC<SpiritIslandOptionsProps> = ({ game }) => {

    return (
        <>
            <Card title="Difficulty Range" size="small">
                <br />
                <Form.Item name="siDifficulty" initialValue={[0, 32]} style={{maxWidth: 500}}>
                    <Slider range max={32} tooltip={{open: true}} />
                </Form.Item>
                When playing with the <i>Larger Island</i> option enabled, if the difficulty range exceeds 18, selecting
                a second adversary or scenario is mandatory; otherwise, no results will be generated. <br />
                Without the <i>Larger Island</i> option enabled, selecting a second adversary or scenario is required for
                difficulty ranges above 14. <br />
                Otherwise, no results will be generated. <br />
                The randomizer will make up to 50 attempts in a single run.<br />
            </Card>
            <br />
            <Card title="Spirits Options" size="small">
                <Form.Item name="spiritComplexity" label="Spirits Complexity (use all if not selected)" initialValue={['low', 'moderate', 'high', 'veryHigh']}>
                    <Checkbox.Group>
                        <Checkbox value="low">Low</Checkbox>
                        <Checkbox value="moderate">Moderate</Checkbox>
                        <Checkbox value="high">High</Checkbox>
                        <Checkbox value="veryHigh">Very High</Checkbox>
                    </Checkbox.Group>
                </Form.Item>
                <Form.Item name="useAspects" label="Include Aspects?" initialValue={'maybe'}>
                    {/*<Switch checkedChildren="Yes" unCheckedChildren="No" defaultChecked={false} />*/}
                    <Segmented<string>
                        options={[
                            { label: 'Yes', value: 'yes', icon: <CheckOutlined style={{color: "green"}} /> },
                            { label: 'No', value: 'no', icon: <CloseOutlined style={{color: "red"}} /> },
                            { label: 'Maybe', value: 'maybe', icon: <QuestionOutlined style={{color: "orange"}} /> },
                        ]}
                    />
                </Form.Item>
            </Card>
            <br />
            <Card title="Board Options" size="small">
                <Form.Item name="boardLayouts" label="Board Layouts" initialValue={['standard', 'alternate', 'archipelago', 'thematicTokens', 'thematicNoTokens']}>
                    <Checkbox.Group>
                        <Space direction="vertical" size="large">
                            <Checkbox value="standard">Standard layout</Checkbox>
                            <Checkbox value="alternate">Alternate layouts</Checkbox>
                            <Checkbox value="archipelago">Archipelago layout (+1)</Checkbox>
                            <Checkbox value="thematicTokens">Thematic (+tokens) (+1)</Checkbox>
                            <Checkbox value="thematicNoTokens">Thematic (no tokens) (+3)</Checkbox>
                        </Space>
                    </Checkbox.Group>
                </Form.Item>
                <Form.Item name="thematicLayout" label="Thematic Layout" initialValue={['definitive', 'random']}>
                    <Checkbox.Group>
                        <Checkbox value="definitive">
                            Definitive &nbsp;
                            <Popover content="There is no officially defined 5 player layout, so either South East or South West will be randomly selected">
                                <InfoCircleOutlined />
                            </Popover>
                        </Checkbox>
                        <Checkbox value="random">
                            Random &nbsp;
                            <Popover content="Random selection of connected boards in definitive layout">
                                <InfoCircleOutlined />
                            </Popover>
                        </Checkbox>
                    </Checkbox.Group>
                </Form.Item>
                <Form.Item name="largerIsland" label="Use Larger Island" initialValue={'maybe'}>
                    <Segmented<string>
                        options={[
                            { label: 'Yes', value: 'yes', icon: <CheckOutlined style={{color: "green"}} /> },
                            { label: 'No', value: 'no', icon: <CloseOutlined style={{color: "red"}} /> },
                            { label: 'Maybe', value: 'maybe', icon: <QuestionOutlined style={{color: "orange"}} /> },
                        ]}
                    />
                </Form.Item>
                <Form.Item name="allowBannedCombo" label="Allow banned board combos" initialValue={'maybe'}>
                    <Segmented<string>
                        options={[
                            { label: 'Yes', value: 'yes', icon: <CheckOutlined style={{color: "green"}} /> },
                            { label: 'No', value: 'no', icon: <CloseOutlined style={{color: "red"}} /> },
                            { label: 'Maybe', value: 'maybe', icon: <QuestionOutlined style={{color: "orange"}} /> },
                        ]}
                    />
                </Form.Item>
            </Card>
            <br/>
            <Card title="Adversaries" size="small">
                <Form.Item name="useAdversary" label="Include Adversary" initialValue={'maybe'}>
                    <Segmented<string>
                        options={[
                            { label: 'Yes', value: 'yes', icon: <CheckOutlined style={{color: "green"}} /> },
                            { label: 'No', value: 'no', icon: <CloseOutlined style={{color: "red"}} /> },
                            { label: 'Maybe', value: 'maybe', icon: <QuestionOutlined style={{color: "orange"}} /> },
                        ]}
                    />
                </Form.Item>
                <Form.Item name="secondAdversary" label="Allow Second Adversary" initialValue={'maybe'}>
                    <Segmented<string>
                        options={[
                            { label: 'Yes', value: 'yes', icon: <CheckOutlined style={{color: "green"}} /> },
                            { label: 'No', value: 'no', icon: <CloseOutlined style={{color: "red"}} /> },
                            { label: 'Maybe', value: 'maybe', icon: <QuestionOutlined style={{color: "orange"}} /> },
                        ]}
                    />
                </Form.Item>
            </Card>
            <br/>
            <Card title="Scenarios" size="small">
                <Form.Item name="useScenario" label="Include Scenario" initialValue={'maybe'}>
                    <Segmented<string>
                        options={[
                            { label: 'Yes', value: 'yes', icon: <CheckOutlined style={{color: "green"}} /> },
                            { label: 'No', value: 'no', icon: <CloseOutlined style={{color: "red"}} /> },
                            { label: 'Maybe', value: 'maybe', icon: <QuestionOutlined style={{color: "orange"}} /> },
                        ]}
                    />
                </Form.Item>
            </Card>
        </>
    );
}

export default SpiritIslandOptions;
