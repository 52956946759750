import React, {useState} from 'react';
import {Card, List, Image, Divider, Typography, Tooltip, Collapse, Col, Row, Popover, CollapseProps} from "antd";
import {GameResult, SimpleEntity} from "../types/types";
import {
    AlertFilled,
    ApartmentOutlined,
    FileOutlined, InfoCircleOutlined,
    RightCircleFilled,
    UserOutlined
} from "@ant-design/icons";
import {noImageFound} from "../utils/helpers";
import {DTwentyIcon} from "../utils/customIcons";
import {BlocksRenderer} from "@strapi/blocks-react-renderer";

const { Text } = Typography;

interface GameResultProps {
    gameData: GameResult;
    noImageGenerate: boolean;
    config: any;
}
const GameResultComponent: React.FC<GameResultProps> = ({gameData, noImageGenerate, config}) => {
    const [blurState, setBlurState] = useState<{ [key: string]: boolean }>({});
    const { playersSetup, commonSetup, generalDescription } = gameData;
    const API_URL = process.env.REACT_APP_MEDIA_URL;
    const teslaMechTilesConf = {
            "cols": {
                "xs": 12,
                "sm": 12,
                "md": 12,
                "lg": 12,
                "xl": 12
            }
        };
    const toggleBlur = (name :string) => {
        setBlurState((prevState :any) => ({
            ...prevState,
            [name]: !prevState[name]
        }));
    };
    const renderSection = (title: string, data: SimpleEntity[] | undefined, key :string, fieldConfig :any = false, isPlayerSetup :boolean = true ) => {
        const columns = fieldConfig.cols ? fieldConfig.cols : isPlayerSetup ? config.playerCols : config.commonCols;
        return (
            data && data.length > 0 && (
                <Row gutter={[16, 16]} style={{ ...(fieldConfig?.parentContainer ? fieldConfig.parentContainer : {}) }}>
                    {data.map((el :SimpleEntity) => {
                        const descriptionItems = [
                            {
                                key: '1',
                                label: 'Description',
                                children: <BlocksRenderer content={el.attributes.description || []} />
                            }
                        ]
                        const descriptionDefaultActiveKey = !el.attributes.hide_description ? ['1'] : undefined;
                        return <Col key={el.id} {...columns} style={{...(fieldConfig?.imgMW ? fieldConfig.imgMW : {})}}>
                            <figure
                                style={{margin: '0 0 8px',}}
                                className={fieldConfig.isBlured ? blurState[el.attributes.Name] ? 'clear' : 'blurred' : ''}
                                onClick={() => toggleBlur(el.attributes.Name)}
                            >
                                <figcaption style={{margin: '0 0 8px'}}>
                                    {el.attributes.Name}
                                    {/* Scythe Options */}
                                    {el.attributes.randomBase && (
                                        <>&nbsp;<Text mark>Base - {el.attributes.randomBase}</Text></>

                                    )}
                                    {el.attributes.factionMatAlert && (
                                        <> <Tooltip title=" Faction and mat is not compatible">
                                            <AlertFilled style={{color: 'red'}} />
                                        </Tooltip></>
                                    )}
                                    {/* end Scythe Options */}
                                    {/* New Frontiers Options */}
                                    {title === 'Empire mat' && (
                                        <>
                                            &nbsp;({el.attributes.PairId})
                                        </>
                                    )}
                                    {/* end New Frontiers Options */}
                                </figcaption>
                                <Image
                                    width="100%"
                                    src={el.attributes.image.data ? `${API_URL}${el.attributes.image.data?.attributes.url}` : noImageFound}
                                    preview={false}
                                    fallback={noImageFound}
                                />
                            </figure>
                            {el.attributes.description && (
                                <Collapse items={descriptionItems} size="small" ghost
                                          defaultActiveKey={descriptionDefaultActiveKey} />
                            )}
                            {el.attributes.scythe_tesla_mech_mod_tiles && el.attributes.scythe_tesla_mech_mod_tiles.data.length > 0 && (
                                renderSection('Faction mech tiles', el.attributes.scythe_tesla_mech_mod_tiles.data, `${key}-scythe_tesla_mech_mod_tiles`, teslaMechTilesConf, isPlayerSetup)
                            )}
                        </Col>
                    })}
                </Row>
            )

        )
    }
    const renderSectionNoImg = (title: string, data: SimpleEntity[] | undefined, key: string) => {
        return (
            data && data.length > 0 && (
                <List
                    dataSource={data}
                    renderItem={(el) => (
                        <List.Item>
                            {el.attributes.Name}
                            {el.attributes.randomBase && (
                                <>&nbsp;<Text mark>Base - {el.attributes.randomBase}</Text></>

                            )}
                            {el.attributes.factionMatAlert && (
                                <> <Tooltip title=" Faction and mat is not compatible">
                                    <AlertFilled style={{color: 'red'}} />
                                </Tooltip></>
                            )}
                            {el.attributes.scythe_tesla_mech_mod_tiles && el.attributes.scythe_tesla_mech_mod_tiles.data.length > 0 && (
                                renderSectionNoImg('Faction mech tiles', el.attributes.scythe_tesla_mech_mod_tiles.data, `${key}-scythe_tesla_mech_mod_tiles`)
                            )}
                            {title === 'Empire mat' && (
                                <>
                                    &nbsp;({el.attributes.PairId})
                                </>
                            )}
                            {el.attributes.Cost && (
                                <>
                                    &nbsp;/ Cost: {el.attributes.Cost}
                                </>
                            )}
                        </List.Item>
                    )}
                />
            )
        )
    }

    const generateItems = (fields :any[], commonSetup :any, config :any, noImageGenerate :any) => {
        const result :any[] = [];
        fields.forEach((field) => {
            if (!commonSetup[field.dataName] || commonSetup[field.dataName].length === 0) {
                return
            }
            const content = (
                <>
                    {config.description && (
                        <>
                            <div>
                                <Text strong>{config.description}</Text>
                            </div>
                            <br />
                        </>
                    )}
                    {field.type === 'text' && (
                        <>
                            {commonSetup[field.dataName]}
                        </>
                    )}
                    {field.type !== 'text' && (
                        <>
                            {(field.noImg || noImageGenerate) ? (
                                renderSectionNoImg(field.title, commonSetup[field.dataName], `common-${field.dataName}`)
                            ) : (
                                renderSection(field.title, commonSetup[field.dataName], `common-${field.dataName}`, field, false)
                            )}
                        </>
                    )}
                </>
            );

            result.push({
                key: `common-${field.dataName}`,
                label: (
                    <>
                        <Divider orientation="left">
                            <FileOutlined /> {field.title} {field.title === 'Small Dev' && commonSetup['smallDdevCount'] && (<> ( {commonSetup['smallDdevCount']} each) </>)}
                        </Divider>
                        {field.description && (
                            <>
                                <div>
                                    <Text strong>
                                        <span dangerouslySetInnerHTML={{ __html: field.description }} />
                                    </Text>

                                    {field.tooltip && (
                                        <>
                                            <Popover content={(<span dangerouslySetInnerHTML={{__html: field.tooltip}} />)}>
                                                <InfoCircleOutlined />
                                            </Popover>
                                            <br />
                                        </>
                                    )}
                                </div>
                                <br />
                            </>
                        )}

                    </>
                ),
                children: content,
                forceRender: true,
            })
        })
        return result
    };

    const items = generateItems(config.commonFields, commonSetup, config, noImageGenerate);
    const defaultActiveKeys = items
        .filter(item => !config.commonFields.find((field :any) => field.dataName === item.key.split('-')[1]).hidden)
        .map(item => item.key);

    const generalDescriptionItems :CollapseProps['items'] = [
        {
            key: '1',
            label: 'General Information',
            children: <div><BlocksRenderer content={generalDescription || []} /></div>
        }
    ]

    return (
        <>
            {generalDescription && (
                <>
                    <Collapse items={generalDescriptionItems} />
                    <br/>
                </>
            )}
            {playersSetup && config.playerFields && playersSetup.length > 0 && (
            <List
                grid={{
                    gutter: 16,
                    xs: 1,
                    sm: 2,
                    md: 3,
                    lg: 4,
                    xl: 5,
                    xxl: 5,
                }}
                dataSource={playersSetup}
                renderItem={(playerData) => (
                    <List.Item key={playerData.playerNumber}>
                        <Card
                            title={(
                            <>
                                <UserOutlined /> Player {playerData.playerNumber}
                                {playerData.color && (<>, Color: <DTwentyIcon style={{color: `${playerData.color}`, fontSize: '20px'}} /></>)}
                            </>
                        )} size="small">
                            {config.playerFields.map((field : any) => {
                                return playerData[field.dataName] && playerData[field.dataName].length > 0 ? (
                                    <div key={`common-${field.dataName}`}>
                                        <Divider orientation="left">
                                            <FileOutlined /> {field.title}
                                        </Divider>
                                        {field.description && (
                                            <>
                                                <div>
                                                    <Text strong>{field.description}</Text>
                                                </div>
                                                <br />
                                            </>
                                        )}
                                        {(field.noImg || noImageGenerate) ? (
                                            renderSectionNoImg(field.title, playerData[field.dataName], `${playerData.playerNumber}-${field.dataName}`)
                                        ) : (
                                            renderSection(field.title, playerData[field.dataName], `${playerData.playerNumber}-${field.dataName}`, field)
                                        )}
                                    </div>
                                ) : (<div key={`common-${field.dataName}`}></div>)
                            })}
                        </Card>
                    </List.Item>
                )}
            />)}
            {commonSetup && Object.keys(commonSetup).length > 0 && (
                <Card title={(
                <>
                    <ApartmentOutlined /> Common Elements
                </>
            )} size="small">
                    <Collapse defaultActiveKey={defaultActiveKeys} items={items} size="small" ghost
                              expandIconPosition='end'
                              expandIcon={
                                ({ isActive }) => <RightCircleFilled  rotate={isActive ? 90 : 0} style={{ position: 'relative', top: 17 }} />
                              }
                    />
                </Card>
            )}
        </>
    );
};

export default GameResultComponent;
