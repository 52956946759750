import {CommonGameParams, GameResult, SimpleEntity} from "../../types/types";
import {filterUniquePairs, getGameFilter, shuffleArray} from "../../utils/helpers";
import {fetchData} from "../api";
import {dynamicParams} from "../params";

interface PlayerSetup {
    playerNumber :number;
    designGoals :SimpleEntity[];
    color :string | null;
}

export const calico = async (params :CommonGameParams): Promise<GameResult> => {

    // Id's of game and expansions
    const gameFilter :number[] = getGameFilter(parseInt(params.gameId), params.expansions)

    const rawDesignGoals :SimpleEntity[] = await fetchData('calico-design-goals', dynamicParams(['Name'], gameFilter, ['image']));

    const shuffledColors :string[] | null = params.colors ? shuffleArray(params.colors) : null
    // Initialize arrays to store final selections
    const playersSetup :PlayerSetup[] =
        Array.from({ length: params.players }, (setup, index) =>
            ({
                playerNumber: index + 1,
                designGoals: shuffleArray(rawDesignGoals).slice(0, 4),
                color: shuffledColors ? shuffledColors[index] : null,
            }));

    const rawCats :SimpleEntity[] = await fetchData('calico-cats', dynamicParams(['Name', 'PairId', 'difficulty'], gameFilter, ['image']));
    const shuffleCats = shuffleArray(rawCats);
    const filterUnicCats :SimpleEntity[] = filterUniquePairs(shuffleCats);

    const catsByDifficulty = filterUnicCats.reduce((acc :any, obj :SimpleEntity) => {
        const difficulty = obj.attributes.difficulty;
        if (!acc[difficulty]) {
            acc[difficulty] = [];
        }
        acc[difficulty].push(obj);
        return acc;
    }, {});
    const selectedCats = Object.keys(catsByDifficulty).map(difficulty => {
        const items = catsByDifficulty[difficulty];
        return items[Math.floor(Math.random() * items.length)];  // Randomly select one from each group
    });
    selectedCats.sort((a,b) => a.attributes.difficulty - b.attributes.difficulty)

    const rawPaths :SimpleEntity[] = await fetchData('calico-path-tiles', dynamicParams(['Name'], gameFilter, ['image']));
    const shufflePaths :SimpleEntity[] = shuffleArray(rawPaths);

    const combinedCats = selectedCats.map((cat) => {
        return [
            cat,
            shufflePaths.shift(),
            shufflePaths.shift()
        ]
    })

    return {
        gameId: params.gameId,
        playersSetup: playersSetup,
        commonSetup: {
            combinedCats: combinedCats
        }
    };

}
